import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StartNewService from "../components/Alerts/StartNewService";
import { useAuth } from "../components/Auth/Auth";
import ListCardItem from "../components/Cards/ListCardItem";
import Layout from "./../components/Templates/Layout";
import "./logservice.scss";
import NewStandardAlert from "./../components/Alerts/NewStandardAlert";

export default function LogService() {
  let customers = useSelector((state) => state.userCustomers.value);
  const [customerSelected, setCustomerSelected] = useState(null);
  const [userCustomers, setUserCustomers] = useState(customers);
  const [returnedCustomers, setReturnedCustomers] = useState(null);
  const [serviceStarted, setServiceStarted] = useState(false);
  const [serviceResponse, setServiceResponse] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const { startService } = useAuth();

  const handleSearch = (event) => {
    let searchParams = event.currentTarget.value;
    let lowerCaseSearchParams = searchParams.toLowerCase();
    let found = userCustomers.filter((customer) =>
      customer.firstname.toLowerCase().includes(lowerCaseSearchParams)
    );
    if (found.length > 0) {
      setReturnedCustomers(found);
    } else {
      console.log("No matching customer found.");
    }
  };
  const selectCustomer = (customer) => {
    console.log(customer);
    setCustomerSelected(customer);
  };
  const startServiceLog = async () => {
    // setServiceStarted(true)
    let response = await startService(customerSelected);
    if (response.status !== false) {
      setServiceResponse(response);
      setServiceStarted(true);
    } else {
      setServiceStarted(true);
      setError(true);
      setServiceResponse(response);
    }
  };
  const closeServiceStartedAlert = () => {
    setServiceStarted(false);
    setError(false);
  };
  const cancelSelection = () => {
    setCustomerSelected(null);
    setError(false);
  };

  if (userCustomers.length === 0) {
    return (
      <Layout>
        <div className="log-a-service">
          <div className="log-head">
            <div>
              <h3>You currently have no customers.</h3>
              <h5>Add a customer to log a service.</h5>
              <br />
              <button
                className="action"
                onClick={() => navigate("/customers/addcustomer")}
              >
                + Add Customer
              </button>
            </div>
          </div>
        </div>
      </Layout>
    );
  } else
    return (
      <Layout>
        {serviceStarted ? (
          <NewStandardAlert
            error={error}
            successMessage={serviceResponse.message}
            warningMessage={serviceResponse.message}
            title={`Service Started`}
            errorTitle={`Service Not Started`}
            close={closeServiceStartedAlert}
            submit={closeServiceStartedAlert}
            successBtn={`Continue`}
            showCancel={false}
          />
        ) : (
          ""
        )}
        {!serviceStarted ? (
          <div className="log-a-service">
            <div className="log-head">
              <h3>Log a Service</h3>
              {customerSelected ? (
                <div>
                  <button onClick={startServiceLog} className="action">
                    Start Service
                  </button>{" "}
                  <button onClick={cancelSelection} className="grey">
                    Cancel
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            <input
              type="text"
              placeholder="Search Customers"
              onChange={handleSearch}
            />
            <div className="results">
              Select a customer
              {returnedCustomers
                ? returnedCustomers?.map((customer, index) => (
                    <div onClick={() => selectCustomer(customer)}>
                      <ListCardItem
                        data={customer.id}
                        key={index}
                        valueOne={`${customer.firstname} ${customer.lastname}`}
                        valueTwo={customer.email}
                        valueThree={`${customer.city} ${customer.state}`}
                        valueFour={``}
                        type={`customer-log`}
                      />
                    </div>
                  ))
                : ""}
            </div>
            <div className="results">
              {userCustomers.length && !returnedCustomers
                ? userCustomers.map((customer, index) => (
                    <div onClick={() => selectCustomer(customer)}>
                      <ListCardItem
                        data={customer.id}
                        key={index}
                        valueOne={`${customer.firstname} ${customer.lastname}`}
                        valueTwo={customer.email}
                        valueThree={`${customer.city} ${customer.state}`}
                        valueFour={``}
                        type={`customer-log`}
                      />
                    </div>
                  ))
                : ""}
            </div>
          </div>
        ) : (
          ""
        )}
      </Layout>
    );
}
