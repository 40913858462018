import React, { useEffect, useState } from "react";
import "./calender.scss";
import {
  MdOutlineArrowBackIosNew,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import Layout from "../../components/Templates/Layout";
export const Calender = () => {
  const d = new Date();

  const [year, setYear] = useState(d.getFullYear());
  const [month, setMonth] = useState(d.getMonth());
  const [calenderArray, setCalenderArray] = useState([]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const createCalender = (year, month) => {
    console.log(year);
    let newYear = year;
    let newMonth = month;
    let CalendarArray = [];
    setYear(newYear);
    setMonth(newMonth);
    let newDaysInMonth = new Date(newYear, newMonth + 1, 0).getDate();
    let newFirstDayOfMonth = new Date(newYear, newMonth, 1);
    let newFirstDayOfWeek = newFirstDayOfMonth.getUTCDay();

    for (let i = 0; i < newFirstDayOfWeek; i++) {
      CalendarArray.push("");
    }
    for (let i = 1; i <= newDaysInMonth; i++) {
      CalendarArray.push(i);
    }
    setCalenderArray(CalendarArray);
  };

  const calenderChangeEvent = (event) => {
    let action = event.currentTarget.id;
    console.log(action);
    let newMonth = month;
    let newYear = year;
    if (action === "decrement month") {
      if (month === 0) {
        newMonth = 11;
        newYear = year - 1;
      }
      if (month >= 1) {
        newMonth = month - 1;
      }
      setMonth(newMonth);
      setYear(newYear);
    }
    if (action === "increment month") {
      console.log(month);
      if (month <= 11) {
        newMonth = month + 1;
      }
      if (month === 11) {
        newMonth = 0;
        newYear = year + 1;
      }
      setMonth(newMonth);
      setYear(newYear);
    }
    if (action === "increment year") {
      newYear = year + 1;
    }
    if (action === "decrement year" && year >= 1) {
      newYear = year - 1;
    }
    createCalender(newYear, newMonth);
  };

  useEffect(() => {
    if (calenderArray.length === 0) {
      createCalender(year, month);
    }
  }, []);

  return (
    <Layout>
      <div className="calender-head">
        <div className="calender-head-content">
          <div>
            <MdOutlineArrowBackIosNew
              id="decrement year"
              onClick={calenderChangeEvent}
            />
          </div>
          <div>
            <h4>{year}</h4>
          </div>
          <div>
            <MdOutlineArrowForwardIos
              id="increment year"
              onClick={calenderChangeEvent}
            />
          </div>
        </div>
        <div className="calender-head-content">
          <div>
            <MdOutlineArrowBackIosNew
              id="decrement month"
              onClick={calenderChangeEvent}
            />
          </div>
          <div>
            <h4>{months[month]}</h4>
          </div>
          <div>
            <MdOutlineArrowForwardIos
              id="increment month"
              onClick={calenderChangeEvent}
            />
          </div>
        </div>
      </div>

      <div className="calender-table">
        <div>Sun</div>
        <div>Mon</div>
        <div>Tue</div>
        <div>Wed</div>
        <div>Thu</div>
        <div>Fri</div>
        <div>Sat</div>
        {calenderArray.map((day, index) => (
          <div className="day" key={index}>
            {day}
          </div>
        ))}
      </div>
    </Layout>
  );
};
