import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../components/Auth/Auth';
import InvoiceFileUpload from '../components/InvoiceComponents/InvoiceFileUpload';
import Layout from '../components/Templates/Layout';
import './inventories.scss'
const exampleJson = require('../components/Data/inventories.json') 

function InventoryForm() {
  const [showFileUploadComponent, setShowFileUploadComponent] = useState(false)
  const [totalProfit, setTotalProfit] = useState(null)
  const { setInventoryDataFromFile, currentInventory } = useAuth()
  let Items = []
  const showFileUpload = () => {
    if(showFileUploadComponent) {
      setShowFileUploadComponent(false)
    }
    else {
      setShowFileUploadComponent(true)
    }
  }

  useEffect(() => {
   const calculateProifits = () => {
    let itemsArray = currentInventory
    if(!currentInventory) {
      itemsArray = exampleJson
    }
    let TotalProfit = []
    for(let item of itemsArray) {
      item.price = Number(item.price)
      item.quantity = Number(item.quantity)
      item.cost = Number(item.cost) || 0
      let total_spent = item.cost * item.quantity
      let total_if_sold_all = (item.price * item.quantity)
      let newItemObject = {
        id: item.id,
        name: item.name,
        description: item.description,
        price: item.price.toFixed(2),
        cost: item.cost.toFixed(2) || 0,
        qty: item.quantity,
        total_possible_revenue: total_if_sold_all.toFixed(2),
        totalCost: total_spent.toFixed(2),
        profit: (total_if_sold_all - total_spent).toFixed(2),
      }
        TotalProfit.push(newItemObject.profit)
        Items.push(newItemObject)
      }
      let sum = TotalProfit.reduce((partialSum, a) => partialSum + a, 0)
      sum = parseFloat(sum)
      sum = Number(sum)
      setTotalProfit(sum.toFixed(2))
      if(!currentInventory) {
        setInventoryDataFromFile(Items)
      } else {
        setInventoryDataFromFile(Items)
      }
   } 
   return calculateProifits()

  }, [currentInventory])
  
  return (
    <Layout>
      <div className='controls'>
        {showFileUploadComponent ? <InvoiceFileUpload />: ""}
        {showFileUploadComponent ? <button onClick={showFileUpload}>Close Upload</button> :
        <button onClick={showFileUpload}>Upload a Spreadsheet</button>
        }
        
      </div>
      <div className='inventories-page'>
        <h1>My Inventory</h1>
      <table>
        <thead>
          <th>ID</th>
          <th>Name</th>
          <th>Description</th>
          <th>Cost</th>
          <th>Price</th>
          <th>Qty</th>
          <th>Total Cost</th>
          <th>TPR</th>
          <th>Profit</th>
        </thead>
        <tbody>
          {currentInventory?.map((item, index) => (
            <tr key={index}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.description}</td>
              <td>{item.cost}</td>
              <td>{item.price}</td>
              <td>{item.qty}</td>
              <td>{item.totalCost}</td>
              <td>{item.total_possible_revenue}</td>
              <td>{item.profit}</td>
            </tr> 
          ))}
        </tbody>
      </table>
      <p>Total Profit: {totalProfit ? totalProfit : 0}</p>
      </div>
    
    {/* <form onSubmit={handleSubmit}>
        {formError ? formError : ""}
      <label htmlFor="item-name">Item Name:</label><br />
      <input
        type="text"
        id="item-name"
        value={itemName}
        onChange={event => setItemName(event.target.value)}
        required
      /><br />
      <label htmlFor="quantity">Quantity:</label><br />
      <input
        type="number"
        id="quantity"
        value={quantity}
        onChange={event => setQuantity(event.target.value)}
        min="1"
        required
      /><br />
      <label htmlFor="price">Price:</label><br />
      <input
        type="number"
        id="price"
        value={price}
        onChange={event => setPrice(event.target.value)}
        min="0"
        step="0.01"
        required
      /><br />
      <input type="submit" value="Submit" />
    </form> */}
    </Layout>

  );
}

export default InventoryForm;
