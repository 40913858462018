import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Templates/Layout";
import { useSelector } from "react-redux";
import { useAuth } from "../../components/Auth/Auth";
import { MdKeyboardArrowLeft } from "react-icons/md";

export default function Inventory() {
  let userCustomers = useSelector((state) => state.userCustomers.value);
  const { fetchCustomerData } = useAuth();
  const navigate = useNavigate();

  const addItem = () => {
    navigate(`/inventory/add`);
  };
  return (
    <Layout>
      <div className="general-page">
        <div className="page-title">
          <div>
            <div className="title-nav" onClick={() => navigate("/dashboard")}>
              <MdKeyboardArrowLeft />
              <h4>Dashboard</h4>
            </div>
            <h1>My Inventory</h1>
          </div>
          <button className="action" onClick={addItem}>
            Add New Item
          </button>
        </div>
        <div className="search-input-wrapper">
          <input type="text" placeholder="Search Inventory..." />
        </div>
      </div>
    </Layout>
  );
}
