import React, { useEffect, useRef, useState } from "react";
import { NewInvoiceContactInfoSection } from "../../components/InvoiceComponents/NewInvoiceContactInfoSection";
import { NewInvoiceItem } from "../../components/InvoiceComponents/NewInvoiceItem";

// import { IoHandLeft } from 'react-icons/io5'
import Layout from "../../components/Templates/Layout";
import { useAuth } from "./../../components/Auth/Auth";

export default function NewInvoice() {
  const [showSaveHead, setShowSaveHead] = useState(false);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [invoiceItemsTotal, setInvoiceItemsTotal] = useState(null);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [customerContactInfo, setCustomerContactInfo] = useState(null);
  const { addNewInvoice } = useAuth();
  const [showCustomerContactInputs, setShowCustomerContactInputs] =
    useState(true);
  // Account Details
  const saveItem = (details) => {
    let currentItems = [...invoiceItems];
    currentItems.push(details);
    setInvoiceItems(currentItems);
    calculateItemsTotal(currentItems);
  };
  const saveContactDetails = (details) => {
    setCustomerContactInfo(details);
    setShowCustomerContactInputs(false);
  };
  const editContactDetails = (details) => {
    setShowCustomerContactInputs(true);
    setCustomerContactInfo(null);
  };
  const saveInvoice = () => {
    if (customerContactInfo === null) {
      console.log("You need to enter the customer contact information");
    }
    setCustomerContactInfo(null);
  };
  const getTime = (receivedData) => {
    var now = new Date(receivedData);
    var day = now.getDate();
    var month = now.getMonth() + 1;
    var year = now.getFullYear();
    var hours = now.getHours();
    var minutes = now.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + ampm;
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    let response = month + "/" + day + "/" + year + " at " + strTime;
    return response;
  };
  const saveInvoiceToDatabase = () => {
    if (customerContactInfo === null || !invoiceItems.length > 0) {
      console.log("You need to enter the customer contact information");
    } else {
      const invoiceData = {
        type: `custom`,
        customer_email: customerContactInfo.customer_email,
        customer_firstname: customerContactInfo.customer_firstname,
        customer_lastname: customerContactInfo.customer_lastname,
        customer_phone: customerContactInfo.customer_phone,
        items: invoiceItems,
        invoice_total: invoiceItemsTotal,
        dateNo: Date.now(),
        formatted_date: getTime(Date.now()),
        status: `unpaid`,
      };
      addNewInvoice(invoiceData);
      setInvoiceItemsTotal(null);
      setInvoiceItems([]);
      setCustomerContactInfo(null);
    }
  };

  const money = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "USD",
  });

  const removeItem = (index) => {
    console.log(`removing item`, index);
    let currentItems = [...invoiceItems];
    let indexToDelete = currentItems.findIndex((obj) => obj.item_id === index);
    currentItems.splice(indexToDelete, 1);
    setInvoiceItems(currentItems);
    calculateItemsTotal(currentItems);
  };

  const calculateItemsTotal = (currentItems) => {
    let total = 0;
    if (currentItems.length > 1) {
      for (let item of currentItems) {
        total = total + item.item_total;
      }
    }
    if (currentItems.length === 1) {
      total = currentItems[0].item_total;
    }
    if (currentItems.length === 0) {
      total = 0;
    }
    setInvoiceItemsTotal(total);
  };

  return (
    <Layout>
      <div className="my-account-page">
        <div className="account-navigation-container">
          <h4>New Invoice</h4>
        </div>
        <div className="contact-details-invoice">
          {customerContactInfo && (
            <ul className="saved-invoice-contact-details">
              <li>{customerContactInfo.customer_firstname}</li>
              <li>{customerContactInfo.customer_lastname}</li>
              <li>{customerContactInfo.customer_email}</li>
              <li>{customerContactInfo.customer_phone}</li>
              <li>
                <button className="action" onClick={editContactDetails}>
                  Edit
                </button>
              </li>
            </ul>
          )}
          <div className="saved-items">
            {invoiceItems.length > 0 && (
              <>
                <h4>Items</h4>
                <ul className="item-details-invoice">
                  <li className="title">Name</li>
                  <li className="title">Description</li>
                  <li className="title">Quantity</li>
                  <li className="title">Price</li>
                  <li className="title">Total</li>
                  <li className="title"></li>
                </ul>
              </>
            )}
            {invoiceItems.map((invoice, index) => {
              return (
                <ul key={invoice.id} className="item-details-invoice">
                  <li>{invoice.item_name}</li>
                  <li>{invoice.item_desc}</li>
                  <li>{invoice.item_qty}</li>
                  <li>{invoice.item_price}</li>
                  <li>{money.format(invoice.item_total)}</li>
                  <li>
                    <button
                      className="warning"
                      onClick={() => removeItem(invoice.item_id)}
                    >
                      Delete
                    </button>
                  </li>
                </ul>
              );
            })}
          </div>
        </div>
        {showCustomerContactInputs && (
          <NewInvoiceContactInfoSection save={saveContactDetails} />
        )}
        <div className="details-container">
          <div className="title-head">
            <h4>Items</h4>
            <button className="action-style-two" disabled>
              Import from Inventory
            </button>
          </div>
          <NewInvoiceItem save={saveItem} />
        </div>
        <button className="action" onClick={saveInvoiceToDatabase}>
          Complete Invoice
        </button>
      </div>
    </Layout>
  );
}
