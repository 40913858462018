import React from "react";
import Layout from "../../components/Templates/Layout";
// import { MdDeleteSweep, MdErrorOutline, MdShare, MdOutlineEditOff, MdCheckCircleOutline } from 'react-icons/md'
import { VscGoToFile } from "react-icons/vsc";
import "./myinvoices.scss";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/Auth/Auth";
import { useSelector } from "react-redux";
import ListCardItem from "../../components/Cards/ListCardItem";

const MyInvoices = (props) => {
  let navigate = useNavigate();
  const { setCurrentInvoice } = useAuth();
  const userInvoices = useSelector((state) => state.userInvoices.value);
  const handleSelection = (event) => {
    event.preventDefault();
    console.log(event.currentTarget);
    // assign data to object
    let invoiceData = {
      name: event.currentTarget.getAttribute("data-name"),
      total: event.currentTarget.getAttribute("data-total"),
      status: event.currentTarget.getAttribute("data-status"),
      id: event.currentTarget.id,
    };
    setCurrentInvoice(invoiceData);
    // set the current invoice data
    console.log(invoiceData);
    // navigtate to page
    navigate(`/dashboard/invoices/${invoiceData.id}`);
  };

  return (
    <Layout>
      <div className="my-invoices">
        <div className="mobile-invoice-list-container">
          <div className="invoice-list-heading">
            <div>
              <h3>My Invoices</h3>
              <span>
                Easily manage your existing invoices or add a new invoice.
              </span>
            </div>
            <div>
              <button
                className="action"
                onClick={() => navigate("/dashboard/invoices/new")}
              >
                + New Invoice
              </button>
            </div>
          </div>
          <div className="search">
            <input type="text" placeholder="Search" />
          </div>
          {/* Filters */}
          <div>
            {userInvoices.map((invoice) => (
              <div id={invoice.id} key={invoice.id} onClick={handleSelection}>
                <ListCardItem
                  valueOne={`${invoice.customer_firstname} ${invoice.customer_lastname}`}
                  valueTwo={invoice.status}
                  valueThree={`${invoice.formatted_date}`}
                  valueFour={`Total: ${invoice.invoice_total}`}
                  type={`invoice`}
                />
              </div>

              // <tr
              //   key={invoice.id}
              //   id={invoice.id}
              //   data-name={invoice.customer_firstname}
              //   data-total={invoice.total}
              //   data-status={invoice.status}
              //   onClick={handleSelection}
              // >
              //   <td>
              //     <VscGoToFile />
              //   </td>
              //   <td>
              //     <span className="bold">
              //       {`${invoice.customer_firstname}  ${invoice.customer_lastname}`}
              //     </span>
              //     <span className="date">{invoice.formatted_date}</span>
              //   </td>
              //   <td>
              //     <span className={invoice.status}>{invoice.status}</span>
              //     <span className={`${invoice.status} amount`}>
              //       ${invoice.invoice_total}
              //     </span>
              //   </td>
              // </tr>
            ))}
          </div>
        </div>
        <div className="table-links">
          {/* <Link to="/">1</Link>
      <Link to="/">2</Link> */}
        </div>
      </div>
    </Layout>
  );
};

export default MyInvoices;
