import { createSlice } from '@reduxjs/toolkit'

export const customerServiceSlice = createSlice({
  name: 'customerService',
  initialState: {
    value: []
  },
  reducers: {
    setcustomerservice: (state, action) => {
      state.value = action.payload
    },
  }, 
})

// Action creators are generated for each case reducer function
export const { setcustomerservice } = customerServiceSlice.actions
export default customerServiceSlice.reducer