import React from "react";
import "./dashboardcard.scss";
import { MdChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function DashboardCard(props) {
  const navigate = useNavigate();

  return (
    <div className="revenue-card">
      <div className="head">
        {props.buttonOneName ? (
          <button onClick={() => navigate(`/${props.buttonOneAction}`)}>
            {props.buttonOneName} <MdChevronRight />
          </button>
        ) : (
          ""
        )}
      </div>
      <div>
        <h4>{props.details}</h4>
      </div>
      <div className="buttons-container">
        <div>
          {props.buttonTwoName ? (
            <button onClick={() => navigate(`/${props.buttonTwoAction}`)}>
              {props.buttonTwoName} <MdChevronRight />
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
