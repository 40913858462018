import React from "react";
import Invoice from "../components/InvoiceComponents/Invoice";
import { useNavigate } from "react-router-dom";
import "./Invoices/myinvoices.scss";

const MyInvoice = () => {
  console.log("Heller");
  const navigate = useNavigate();
  // get invoice data from user invoices
  return (
    <>
      <div className="invoice-manager">
        <button onClick={() => window.print()}>Print</button>
        <button onClick={() => navigate("/dashboard/invoices")}>Go Back</button>
      </div>
      <Invoice />
    </>
  );
};

export default MyInvoice;
