import { createSlice } from '@reduxjs/toolkit'

export const customerInvoicesSlice = createSlice({
  name: 'customerInvoices',
  initialState: {
    value: []
  },
  reducers: {
    setcustomerinvoices: (state, action) => {
      state.value = action.payload
    },
  }, 
})

// Action creators are generated for each case reducer function
export const { setcustomerinvoices } = customerInvoicesSlice.actions
export default customerInvoicesSlice.reducer