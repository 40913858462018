import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../Auth/Auth";
import { MdOutlineClose } from "react-icons/md";
import "./updateaccountalert.scss";
export default function UpdateAccountAlert() {
  const { needsToUpdate, setNeedsToUpdate } = useAuth();
  const navigate = useNavigate();
  if (needsToUpdate) {
    return (
      <div className="update-account">
        <div>
          <p>
            We have rolled out some new features, to better assist you, please
            make sure your account is up-to-date. You will not have access to
            certain features until your account is updated. Be sure to reset the
            app for our latest updates.
          </p>
          <button
            className="link"
            onClick={() => navigate("/account/myaccount")}
          >
            Update my Account
          </button>
        </div>
        <MdOutlineClose onClick={() => setNeedsToUpdate(false)} />
      </div>
    );
  }
}
