import React, { useState } from "react";
import { MdOutlineUploadFile } from "react-icons/md";
import { useSelector } from "react-redux";

import "./service.scss";

import { useNavigate } from "react-router-dom";
import { useAuth } from "./../../components/Auth/Auth";
import NewStandardAlert from "../../components/Alerts/NewStandardAlert";
import Layout from "../../components/Templates/Layout";
import { MdKeyboardArrowLeft } from "react-icons/md";

export default function Service() {
  const service = useSelector((state) => state.customerService.value);
  const [customerService, setCustomerService] = useState(service);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [viewImages, setViewImages] = useState(false);
  const [showServiceDeletedAlert, setShowServiceDeletedAlert] = useState(false);
  const [deletedServiceStatus, setDeletedServiceStatus] = useState(null);
  const { removeServiceFromUsersServicesAndUpdate, getHours } = useAuth();
  console.log(customerService);
  const navigate = useNavigate();

  ////////STEP ONE SHOW CONFIRM DELETE///////
  const toggleDeleteAlert = async () => {
    setShowDeleteAlert(false);
  };
  ///////IF COMMITTED TO DELETE///////
  const handleDelete = async () => {
    setShowDeleteAlert(false);
    let result = await removeServiceFromUsersServicesAndUpdate(customerService);
    if (result) {
      setShowServiceDeletedAlert(true);
      setDeletedServiceStatus(false);
    } else {
      setShowServiceDeletedAlert(true);
      setDeletedServiceStatus(true);
    }
  };
  //////CONFIRM STATUS OF DELETE///////
  const handleSubmit = () => {
    setShowServiceDeletedAlert(false);
    setDeletedServiceStatus(null);
    navigate("/dashboard");
  };

  ////////TOGGLES LAST ALERT OFF /////
  const toggleServiceDeletedAlert = async () => {
    setDeletedServiceStatus(false);
  };

  // After Deleted  //

  let deleteMessage = `You are about to delete a service log for ${customerService.firstname} ${customerService.lastname}, are you sure?`;

  let ServiceDeletedAlert = () => {
    return (
      <NewStandardAlert
        error={deletedServiceStatus}
        successMessage={`Service Was Successfully Deleted`}
        warningMessage={`Something went wrong`}
        title={`Delted Successfully`}
        errorTitle={`Delete Failed`}
        close={toggleServiceDeletedAlert}
        submit={handleSubmit}
        successBtn={`Continue`}
        showCancel={false}
      />
    );
  };

  let DeleteAlert = () => {
    return (
      <NewStandardAlert
        error={false}
        successMessage={deleteMessage}
        warningMessage={``}
        title={`Delete Service Log`}
        errorTitle={``}
        close={toggleDeleteAlert}
        submit={handleDelete}
        successBtn={`Delete Service`}
        showCancel={true}
      />
    );
  };

  const getTime = (receivedData) => {
    var now = new Date(receivedData);
    var day = now.getDate();
    var month = now.getMonth() + 1;
    var year = now.getFullYear();
    var hours = now.getHours();
    var minutes = now.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + ampm;
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    let response = month + "/" + day + "/" + year + " at " + strTime;
    return response;
  };

  return (
    <Layout>
      {showDeleteAlert ? DeleteAlert() : ""}
      {showServiceDeletedAlert ? ServiceDeletedAlert() : ""}
      <div className="general-page">
        <div className="page-title">
          <div>
            <div className="title-nav">
              <MdKeyboardArrowLeft />
              <h4 onClick={() => navigate("/myservicelogs")}>Services</h4>
            </div>
            <h1>Service Details</h1>
          </div>
          <button className="action" onClick={"/"}>
            Add Customer
          </button>
        </div>
        <div className="page-section-head-one">Contact Details</div>
        <div className="page-section-content-one">
          <ul>
            <li className="title">Address</li>
            <li>
              {customerService.firstname} {customerService.lastname}
              {customerService.suite}
            </li>
            <li>
              {customerService.city} {customerService.state}
              {customerService.zip}
            </li>
            <li></li>
          </ul>
          <ul>
            <li className="title">Contact</li>
            <li>{customerService.phone}</li>
            <li>{customerService.email}</li>
            <li></li>
          </ul>
        </div>
        <div className="page-section-head-one">Service Details</div>

        <div className="page-section-content-one">
          <ul>
            <ul>
              <li className="title">Started Time</li>
              <li>{getTime(customerService.startedAt)}</li>
              <li className="spacer"></li>
              <li className="title">Ended Time </li>
              <li>{getTime(customerService.stoppedAt)}</li>
            </ul>
            <li className="title">Contact</li>
            <li>{customerService.phone}</li>
            <li>{customerService.email}</li>
            <li></li>
          </ul>
          <ul>
            <li className="title">Total Hours: </li>
            <li>{customerService.hours}</li>
            <li className="spacer"></li>
            <li className="title">Itemized Items</li>
          </ul>
        </div>
        <div className="page-section-head-one">Notes</div>
        <div className="page-section-content-one">
          <ul>
            <li>{customerService.notes}</li>
          </ul>
        </div>

        <h5>Under Development</h5>
        <div className="actions-wrapper">
          {customerService.images.length > 0 && (
            <>
              {!viewImages ? (
                <button
                  className="action-style-two"
                  onClick={() => setViewImages(true)}
                >
                  View Images
                </button>
              ) : (
                <button
                  className="action-style-two"
                  onClick={() => setViewImages(!viewImages)}
                >
                  Close Images
                </button>
              )}
            </>
          )}
          {/* <button className="action-style-two">Edit</button> */}
          <button className="warning" onClick={() => setShowDeleteAlert(true)}>
            Delete
          </button>
          {/* <button className="action">Generate Invoice</button> */}
        </div>
        {viewImages && (
          <div className="image-uploads-wrapper">
            {customerService.images.map((image, index) => (
              <div>
                <img
                  src={image.url}
                  alt="service stash"
                  title="service stash"
                  key={index}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
}
