import React from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Templates/Layout";
import { useSelector } from "react-redux";
import { useAuth } from "../../components/Auth/Auth";
import ListCardItem from "../../components/Cards/ListCardItem";
import { MdKeyboardArrowLeft } from "react-icons/md";

export default function Customers() {
  let userCustomers = useSelector((state) => state.userCustomers.value);
  const { fetchCustomerData } = useAuth();
  const navigate = useNavigate();

  const handleCustomerLink = (id) => {
    console.log("clicked");
    fetchCustomerData(id);
    navigate(`/customer/${id}`);
  };
  const goToNewCustomerPage = () => {
    navigate(`/customers/addcustomer`);
  };
  return (
    <Layout>
      <div className="general-page">
        <div className="page-title">
          <div>
            <div className="title-nav">
              <MdKeyboardArrowLeft />
              <h4 onClick={() => navigate("/dashboard")}>Dashboard</h4>
            </div>
            <h1>Customers</h1>
          </div>
          <button className="action" onClick={goToNewCustomerPage}>
            Add Customer
          </button>
        </div>
        <div className="search-input-wrapper">
          <input type="text" placeholder="Search Customers..." />
        </div>
        {userCustomers.map((customer, index) => (
          <div
            data={customer.id}
            key={index}
            onClick={() => handleCustomerLink(customer.id)}
          >
            <ListCardItem
              data={customer.id}
              key={index}
              valueOne={`${customer.firstname} ${customer.lastname}`}
              valueTwo={customer.email}
              valueThree={`${customer.city} ${customer.state}`}
              valueFour={`Services: ${customer.service_count}`}
              type={`customer-log`}
            />
          </div>
        ))}
      </div>
    </Layout>
  );
}
