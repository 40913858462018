import React from "react";
import "./deleteclient.scss";
import { MdSdCardAlert, MdClose } from "react-icons/md";
import { useAuth } from "../Auth/Auth";
import { Navigate, useNavigate } from "react-router-dom";

export default function DeleteClient(props) {
  const { deleteCustomer } = useAuth();
  let customerId = props.customer;
  const navigate = useNavigate();
  const deleteClient = async (customerId) => {
    deleteCustomer(customerId);
    props.close();
    navigate("/dashboard");
  };
  const cancel = async (customerId) => {
    props.close();
  };
  return (
    <div className="add-customer-alert-wrapper">
      <div className="add-customer-alert">
        <div className="alert-head">
          <p>Deleting ID: {customerId}</p>
        </div>
        <div className="content">
          <p>
            By deleting this client, you will lose all client data and all
            service logs. Are you sure you want to delete this customer?
          </p>
        </div>
        <div className="button-container">
          <button className="action" onClick={() => deleteClient(customerId)}>
            Delete Customer
          </button>
          <button className="grey" onClick={cancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
