import React, { useState, useEffect, useRef } from "react";
import { BiMenuAltRight } from "react-icons/bi";
import logo from "../../Images/ss_logo_long_v2.svg";
import { useAuth } from "../Auth/Auth";
import { Link, useNavigate } from "react-router-dom";
import "./navbar.scss";
import { useSelector, useDispatch } from "react-redux";

export default function Navbar() {
  let myRef = useRef(null);
  let activeRef = useRef(null);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const active = "active";

  const navigate = useNavigate();
  const { logout } = useAuth();
  const user = useSelector((state) => state.user.value);
  const handleMouseExit = (event) => {
    if (showSideMenu) {
      setShowSideMenu(false);
    }
  };

  return (
    <>
      <div className="nav-bar">
        <div>
          <img
            src={logo}
            alt={`service stash logo`}
            onClick={() => navigate("/dashboard")}
          />
        </div>
        <BiMenuAltRight
          onClick={() => setShowSideMenu(true)}
          id="menu"
          onMouseOut={handleMouseExit}
        />
      </div>
      <div
        className={
          !showSideMenu ? `side-menu-wrapper` : "side-menu-wrapper open"
        }
      >
        {user ? (
          <ul>
            <li className="header">Member Access</li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/account/myaccount">My Account</Link>
            </li>
            <li>
              <Link to="/customers">My Customers</Link>
            </li>
            <li>
              <Link to="/myservicelogs">Services</Link>
            </li>
            <li>
              <Link to={`/dashboard/employees`}>Employees</Link>
            </li>
            <li>
              <button onClick={logout}>Logout</button>
            </li>
            <li className="header">Site</li>
            <li>
              <Link to="/documentation">ServiceStash.Com</Link>
            </li>
            <li>
              <Link to="/updates">Updates</Link>
            </li>
            <li>
              <Link to="/premium">Premium</Link>
            </li>
            <li>
              <Link to="/documentation">Documentation</Link>
            </li>
          </ul>
        ) : (
          <ul>
            <li className="header">Site</li>
            <li>
              <Link to="/documentation">ServiceStash.Com</Link>
            </li>
            <li>
              <Link to="/updates">Updates</Link>
            </li>
            <li>
              <Link to="/premium">Premium</Link>
            </li>
            <li>
              <Link to="/documentation">Documentation</Link>
            </li>
          </ul>
        )}
      </div>
      <div className="desktop-nav">
        <div>
          <img src={logo} alt={`service stash logo`} />
        </div>
        <ul>
          <li>
            <Link to={`/dashboard`}>Dashboard</Link>
          </li>
          <li>
            <Link to={`/account/myaccount`}>My Account</Link>
          </li>
          <li>
            <Link to={`/customers`}>Customers</Link>
          </li>
          <li>
            <Link to={`/myservicelogs`}>Services</Link>
          </li>
          <li>
            <Link to={`/dashboard/employees`}>Employees</Link>
          </li>
          <li>
            <Link to={`/premium`}>Premium Services</Link>
          </li>
          <li>
            <button onClick={logout}>Logout</button>
          </li>
        </ul>
      </div>
    </>
  );
}
