import React from "react";
import { useState } from "react";

export const EditCustomerForm = (props) => {
  const customerData = props.customerData;
  const [firstname, setFirstName] = useState(customerData.firstname);
  const [lastname, setLastName] = useState(customerData.lastname);
  const [street, setStreet] = useState(customerData.street);
  const [suite, setSuite] = useState(customerData.suite);
  const [city, setCity] = useState(customerData.city);
  const [state, setState] = useState(customerData.state);
  const [zip, setZip] = useState(customerData.zip);
  const [phone, setPhone] = useState(customerData.phone);
  const [email, setEmail] = useState(customerData.email);

  const handleChange = (event) => {
    event.preventDefault();
    let id = event.currentTarget.id;
    if (id === `firstname`) {
      let value = event.currentTarget.value;
      setFirstName(event.currentTarget.value);
      console.log(value);
    }
    if (id === `lastname`) {
      let value = event.currentTarget.value;
      setLastName(event.currentTarget.value);
      console.log(value);
    }
    if (id === `street`) {
      let value = event.currentTarget.value;
      setStreet(event.currentTarget.value);
      console.log(value);
    }
    if (id === `suite`) {
      let value = event.currentTarget.value;
      setSuite(event.currentTarget.value);
      console.log(value);
    }
    if (id === `city`) {
      let value = event.currentTarget.value;
      setCity(event.currentTarget.value);
      console.log(value);
    }
    if (id === `state`) {
      let value = event.currentTarget.value;
      setState(event.currentTarget.value);
      console.log(value);
    }
    if (id === `zip`) {
      let value = event.currentTarget.value;
      setZip(event.currentTarget.value);
      console.log(value);
    }
    if (id === `phone`) {
      let value = event.currentTarget.value;
      setPhone(event.currentTarget.value);
      console.log(value);
    }
    if (id === `email`) {
      let value = event.currentTarget.value;
      setEmail(event.currentTarget.value);
      console.log(value);
    }
  };

  const update = () => {
    let updatedCustomer = {
      firstname: firstname,
      lastname: lastname,
      street: street,
      suite: suite,
      city: city,
      state: state,
      email: email,
      phone: phone,
      zip: zip,
      service_count: 0,
      last_updated: Date.now(),
      id: customerData.id,
    };
    props.submit(updatedCustomer);
  };

  return (
    <>
      <div className="page-section-head-one">Edit Customer</div>
      <div className="page-section-content-one">
        <div>
          <label>First Name</label>
          <input
            type="text"
            id={`firstname`}
            value={firstname}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Last Name</label>
          <input
            type="text"
            id={`lastname`}
            value={lastname}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Street Address</label>
          <input
            type="text"
            id={`street`}
            value={street}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Suite</label>
          <input
            type="text"
            id={`suite`}
            value={suite}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>City</label>
          <input type="text" id={`city`} value={city} onChange={handleChange} />
        </div>

        <div>
          <label>State</label>
          <input
            type="text"
            id={`state`}
            value={state}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Zip</label>
          <input type="text" id={`zip`} value={zip} onChange={handleChange} />
        </div>

        <div>
          <label>Phone</label>
          <input
            type="text"
            id={`phone`}
            value={phone}
            onChange={handleChange}
          />
        </div>

        <div>
          <label>Email</label>
          <input
            type="text"
            id={`email`}
            value={email}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="actions-wrapper">
        <button className="action" onClick={update}>
          Save
        </button>
      </div>
    </>
  );
};
