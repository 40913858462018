import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/Templates/Layout";
import {
  MdOutlineContactMail,
  MdInput,
  MdKeyboardArrowLeft,
} from "react-icons/md";
import "./activeservice.scss";
import NewStandardAlert from "../../components/Alerts/NewStandardAlert";
import ServiceSubmissionForm from "./ServiceSubmissionForm";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/Auth/Auth";
import { useSelector } from "react-redux";

export default function ActiveService() {
  let service = useSelector((state) => state.userCurrentService.value);
  const [showFinalizationAlert, setShowFinalizationAlert] = useState(false);
  const [showSubmissionForm, setShowSubmissionForm] = useState(false);
  const imageUploadRef = useRef(null);
  const [image, setImage] = useState(null);
  const navigate = useNavigate();
  const { finalizeService, getHours, handleServiceImageUpload } = useAuth();
  const getTime = (receivedData) => {
    var now = new Date(receivedData);
    var day = now.getDate();
    var month = now.getMonth() + 1;
    var year = now.getFullYear();
    var hours = now.getHours();
    var minutes = now.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + ampm;
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    let response = month + "/" + day + "/" + year + " at " + strTime;
    return response;
  };

  const closeFinalizationAlert = () => {
    setShowFinalizationAlert(false);
  };

  const completeService = async () => {
    await finalizeService(service);
    setShowFinalizationAlert(false);
    navigate("/dashboard");
  };

  const beginFinalization = () => {
    console.log("finalize warning");
    setShowFinalizationAlert(true);
  };

  const closeAlert = () => {
    setShowFinalizationAlert(false);
  };
  const handleImageChange = (event) => {
    if (event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  };
  const uploadImage = async () => {
    await handleServiceImageUpload(service, image);
    setImage(null);
  };

  useEffect(() => {}, [service]);

  return (
    <Layout>
      <div className="general-page">
        <div className="page-title">
          <div>
            <div className="title-nav">
              <MdKeyboardArrowLeft />
              <h4 onClick={() => navigate("/dashboard")}>Dashboard</h4>
            </div>
            <h1>Service Details</h1>
          </div>
        </div>
        <div className="page-section-head-two">
          Service Log for {service.firstname} {service.lastname}
          <button onClick={() => beginFinalization()} className="link green">
            Finalize
          </button>
        </div>
        <div className="page-section-content-two">
          <ul>
            <li className="title">Address</li>
            <li>{service.street}</li>
            <li>{service.suite}</li>
            <li>
              {service.city} {service.state} {service.zip}
            </li>
          </ul>
          <ul>
            <li className="title">Service Details</li>
            <li>
              <span>Started On:</span> {getTime(service.startedAt)}
            </li>
            <li>
              <span>Stopped At:</span>
              {service.stoppedAt ? getTime(service.stoppedAt) : " Ongoing"}
            </li>
            <li>
              <span>Hours:</span>{" "}
              {!service.stoppedAt
                ? getHours(Date().now, service.startedAt)
                : getHours(service.stoppedAt, service.startedAt)}
            </li>
            <li>
              <span>Reviewed: </span> {service.reviewed ? "True" : "False"}
            </li>
          </ul>
        </div>
        <div className="page-section-head-two">
          <div className="input-wrapper">
            {image?.name || ``}
            {image?.name ? (
              <button className="action" onClick={uploadImage}>
                Upload
              </button>
            ) : (
              <>
                <label htmlFor="file-upload" className="custom-file-upload">
                  Upload Image
                </label>
                <input
                  id="file-upload"
                  type="file"
                  onChange={handleImageChange}
                  ref={imageUploadRef}
                />
              </>
            )}
          </div>
          {image?.name && (
            <button className="grey" onClick={() => setImage(null)}>
              Cancel
            </button>
          )}
        </div>
        <div className="page-section-content-two">
          <div className="image-uploads-wrapper">
            {service.images?.map((image, index) => {
              return (
                <div key={index}>
                  <img src={image.url} alt="test" key={index} />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {showFinalizationAlert ? (
        <NewStandardAlert
          title={`Service Log Alert`}
          error={false}
          errorMessage={``}
          successMessage={`You are about to end the current service. Are you sure you want to?`}
          successBtn={`Continue`}
          showCancel={`Cancel`}
          close={closeAlert}
          submit={completeService}
        />
      ) : (
        ""
      )}
      {showSubmissionForm ? (
        <ServiceSubmissionForm serviceDetails={service} />
      ) : (
        <div className="customer-details-wrapper"></div>
      )}
    </Layout>
  );
}
