import React, { useState } from 'react'
import './addcustomeralert.scss'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../Auth/Auth';
export default function AddCustomerAlert(props) {
    let success = props.success
    let error = props.error
    let warningMessage = props.warningMessage
    let customer = props.customer
    const { addNewCustomer } = useAuth()
    
    const [submitted, setSubmitted] = useState(false)
    const navigate = useNavigate()
    const close = () => {
        props.close()
    }

    const backToDash = async () => {
        await props.close()
        navigate("/customers")
    }
    
    const submit = async() => {
        setSubmitted(true)
        await addNewCustomer(customer)
    }      

    if(success) {
        return (
            <div className='add-customer-alert-wrapper'>
            {!submitted ?
            <div className='add-customer-alert'> 
                <div className='alert-head'>
                    <p>{warningMessage ? "Not all fields were filled out" : "Information Valid"}</p>
                </div>
                <div className='content'>
                    <p>Are you sure you want to save this customer?</p>
                </div>
                <div className='button-container'>
                    <button className='action' onClick={submit}>Add Customer</button>
                    <button className='link' onClick={close}>Edit Details</button>
                </div>    
            </div> : 
            <div className='add-customer-alert'> 
                <div className='alert-head'>
                    <p>New Customer added</p>
                </div>
                <div className='content'>
                    <p>{customer.firstname} {customer.lastname} was successfuly added.</p>
                </div>
                <div className='button-container'>
                    <button className='action' onClick={backToDash}>Back to Customers</button>
                    <button className='link' onClick={close}>Add a New Customer</button>
                </div>    
            </div>           
            
            }

        </div>
        )
    } else {
        return (
            <div className='add-customer-alert-wrapper'>
            <div className='add-customer-alert'>
            <div className='alert-head'>
            <p>Something went wrong...</p>
            </div>
            <div className='content'>
                <p>{error}</p>
            </div>
                <div className='button-container'>
                <button className='action' onClick={close}>Go Back</button>
                <button className='link' onClick={backToDash}>Back to Dashboard</button>
                </div>
            </div>
        </div>
        )
    }
}
