import { createSlice } from '@reduxjs/toolkit'

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    value: []
  },
  reducers: {
    setcustomer: (state, action) => {
        state.value = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setcustomer } = customerSlice.actions

export default customerSlice.reducer