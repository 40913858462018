import React, { useRef } from "react";
import { useAuth } from "../../components/Auth/Auth";
import { useNavigate } from "react-router-dom";

export default function ServiceSubmissionForm(props) {
  let service = props.serviceDetails;
  const inputRef = useRef();
  const { addNewService } = useAuth();
  const navigate = useNavigate();

  function getHours(endTime, date) {
    var current = new Date(endTime);
    var previous = new Date(date);
    var diff = current - previous;
    var hours = Math.floor(diff / 1000 / 60 / 60);
    var minutes = Math.floor(diff / 1000 / 60) % 60;
    let convertedMinutes = minutes / 60;
    let totalTime = hours + convertedMinutes;
    let timeDifference = {
      hours: hours,
      minutes: minutes,
      minutesInDec: convertedMinutes.toFixed(2),
      totalTime: totalTime.toFixed(2),
    };
    return totalTime.toFixed(2);
  }

  let updatedService = {
    ...service,
    notes: "",
    reviewed: true,
    items: [],
    cid: service.id,
    hours: getHours(service.stoppedAt, service.startedAt),
  };
  const handleChange = (event) => {
    updatedService.notes = inputRef.current.value;
    console.log(updatedService);
  };

  const handleSave = () => {
    console.log("called");
    console.log(updatedService);
    if (updatedService.notes !== "" || "  ") {
      addNewService(updatedService);
      alert("Service Completed");
      navigate("/dashboard");
    } else {
      alert("You need to add something in the notes");
    }
  };

  return (
    <div className="customer-details-wrapper">
      <div className="details-container">
        <div className="section-head">
          <h4>Additional Details</h4>
        </div>
        <div className="form-container">
          <span>Notes</span>
          <textarea
            placeholder={`Add details`}
            ref={inputRef}
            onChange={handleChange}
          />
          <div className="items-container">
            <div className="item">Example Item Here | QTY:1 | AMOUNT:2.00</div>
            <div>
              <button className="blue">+ Add Item (soon)</button>
            </div>
          </div>
          <div></div>
          <div className="button-container">
            <button className="action" onClick={handleSave}>
              Submit Log
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
