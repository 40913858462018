import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    value: null
  },
  reducers: {
    login: (state, action) => {
        state.value = action.payload
    },
    logoutuser: (state) => {
        state.value = null
    },
    update: (state, action) => {
        // Add on to user object
        state.value = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { login, logoutuser, update } = userSlice.actions

export default userSlice.reducer