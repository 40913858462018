import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { IoHandLeft } from 'react-icons/io5'
import { useAuth } from "../../components/Auth/Auth";
import Layout from "../../components/Templates/Layout";
import "./account.scss";

export default function MyAccount() {
  const { updateUserInfo } = useAuth();
  const user = useSelector((state) => state.user.value);
  const accountFormRef = useRef(null);
  const [showSaveHead, setShowSaveHead] = useState(false);
  const navigate = useNavigate();
  const handleEmailChange = (event) => {
    event.preventDefault();
    setShowSaveHead(!showSaveHead);
  };
  // Account Details
  const emailRef = useRef(null);
  const firstnameRef = useRef(null);
  const lastnameRef = useRef(null);

  const handleSave = (event) => {
    event.preventDefault();
    // WORKING JUST STOPPING IT
    // if(target === "account") {
    let objectToUpdate = {
      email: emailRef.current.value,
      firstname: firstnameRef.current.value,
      lastname: lastnameRef.current.value,
    };
    console.log(objectToUpdate);
    //     updateUserInfo(objectToUpdate)
    // }
  };

  return (
    <Layout>
      <div className="my-account-page">
        <div className="account-navigation-container">
          <h4>My Account Details</h4>
          <div>
            <button className="active">Account </button>
            <button onClick={() => navigate("/account/mybusiness")}>
              Business{" "}
            </button>
            <button>Preferences </button>
          </div>
        </div>
        <div className="details-container">
          <div className="title-head">
            <h4>This page is under development</h4>
          </div>
          <div className="danger-container" ref={accountFormRef}>
            <div>
              <p>Informating and changes on here may not work properly</p>
            </div>
          </div>
        </div>
        <div className="details-container">
          <div className="title-head">
            <h4>Account Information</h4>
          </div>
          <div className="form-container" ref={accountFormRef}>
            <span>Personal Email</span>
            <input
              type="text"
              placeholder={user?.email}
              ref={emailRef}
              onChange={handleEmailChange}
            />
            <span>First Name</span>
            <input
              type="text"
              placeholder={user?.firstname || "needs updated"}
              ref={firstnameRef}
              onChange={handleEmailChange}
            />
            <span>Last Name</span>
            <input
              type="text"
              placeholder={user?.lastname || "needs updated"}
              ref={lastnameRef}
              onChange={handleEmailChange}
            />
          </div>
          <div className="title-foot">
            <div className="actions">
              <button id="account-close" onClick={handleSave}>
                Cancel
              </button>
              <button className="save" id="account" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="details-container">
          <div className="title-head">
            <h4>Subscriptions</h4>
          </div>
          <div className="form-container" ref={accountFormRef}>
            <span>No Subscriptions</span>
          </div>
          {/* <div className='title-foot'>
                    <div className='actions'>
                        <button id="account-close" onClick={handleSave}>Cancel</button>
                        <button className='save' id="account" onClick={handleSave}>Save</button>
                    </div>
                </div> */}
        </div>
        <div className="details-container">
          <div className="title-head">
            <h4>Danger Zone</h4>
          </div>
          <div className="danger-container" ref={accountFormRef}>
            <div>
              <p>
                Deleting this account will also remove all information and deta
                related to it
              </p>
              <button className="save" id="account" onClick={handleSave}>
                Delete Account
              </button>
            </div>
          </div>
        </div>
        {/* <div className='details-container'>
                <div className='title-head'>
                    <h4>Business Information</h4>
                </div>
                <div className='form-container'>
                    <span>Busines Name</span>
                    <input type="text" placeholder={user?.business_name || `needs updates`} onChange={handleEmailChange}/>
                    <span>Business Email</span>
                    <input type="text" placeholder={user?.business_email || `needs updates`} onChange={handleEmailChange}/>
                    <span>Street Address</span>
                    <input type="text" placeholder={user?.business_street || `needs updates`} onChange={handleEmailChange}/>
                    <span>Apt / Suite</span>
                    <input type="text" placeholder={user?.business_suite || `needs updates`} onChange={handleEmailChange}/>
                    <span>City</span>
                    <input type="text" placeholder={user?.business_city ||`needs updates`} onChange={handleEmailChange}/>
                    <span>State</span>
                    <input type="text" placeholder={user?.business_state || `needs updates`}  onChange={handleEmailChange}/>
                    <span>Zip</span>
                    <input type="text" placeholder={user?.business_zip ||`needs updates`}  onChange={handleEmailChange}/>
                </div>
                <div className='title-foot'>
                    <div className='actions'>
                        <button id="business-close" onClick={handleSave}>Cancel</button>
                        <button className='save' id="business" onClick={handleSave}>Save</button>
                    </div>
                </div>
            </div> */}
        <section className="business-details"></section>
      </div>
    </Layout>
  );
}
