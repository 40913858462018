import React, { useState } from 'react'
import Layout from '../components/Templates/Layout'
import './premium.scss'
import { BsBoxArrowInDownRight, BsBoxArrowInUpLeft } from 'react-icons/bs'

export default function Premium() {
  const [dropDownOne, setDropDownOne] = useState(false)
  const [dropDownTwo, setDropDownTwo] = useState(false)

  return (
    <Layout>
      <div className="premium">
        <h3>Premium Features</h3>
          <div className='drop-down'>
            {dropDownOne ? <div className='drop-down-title' onClick={()=> setDropDownOne(false)}><h4>Why go premium?</h4><BsBoxArrowInUpLeft /></div>
            : 
            <div className='drop-down-title' onClick={()=> setDropDownOne(true)}><h4>Why go premium?</h4><BsBoxArrowInDownRight /></div>
            }
            {dropDownOne ? <div className='drop-down-content'>
              <h4>Early Access</h4>
              <p>Premium members will have access to the newest features we role out, and although there will be bumps, you will be able to access all the latest features.</p>
              <p>In addition to early access, you will also have additional acces to premium only features.</p>
              <p>Also, you will be providing developer support to assist in creating this application. These things take time, and any additional support is appreicated as I develope this single handedly.</p>
            </div>: ""}
          </div>

          <div className='drop-down'>
            {dropDownTwo ? <div className='drop-down-title' onClick={()=> setDropDownTwo(false)}><h4>What are the premium features?</h4><BsBoxArrowInUpLeft /></div>
            : 
            <div className='drop-down-title' onClick={()=> setDropDownTwo(true)}><h4>What are the premium features?</h4><BsBoxArrowInDownRight /></div>
            }
            {dropDownTwo ? <div className='drop-down-content'>
              <h4>Premium Features</h4>
              <p><a target="_blank" href="https://app.servicestash.com" rel="noreferrer">ServiceStash</a> looks to be adding tons of new services available to our platform. Not all features have been rolled out but here are a few things you can expect.</p>
              <ul>
                <li>Invoicing</li>
                <li>Inventories</li>
                <li>Quotes</li>
                <li>Accounting Features</li>
                <li>Scheduling Features</li>
              </ul> 
            </div>: ""}
          </div>
        </div>
    </Layout>
  )
}
