import { configureStore } from "@reduxjs/toolkit";

import userReducer from "../features/user/userSlice";
import userServicesReducer from "../features/user/userServicesSlice";
import userCurrentServiceReducer from "../features/user/userCurrentServiceSlice";
import userCustomersReducer from "../features/user/userCustomersSlice";
import userInvoiceReducer from "../features/user/userInvoicesSlice";

import customerServiceReducer from "../features/customers/customerServiceSlice";
import customerServiceImageReducer from "../features/customers/customerServiceImagesSlice";
import customerServicesReducer from "../features/customers/customerServicesSlice";
import customerInvoicesReducer from "../features/customers/customerInvoicesSlice";
import customerInvoiceReducer from "../features/customers/customerInvoiceSlice";
import customerReducer from "../features/customers/customerSlice";
import { userCurrentServiceSlice } from "./../features/user/userCurrentServiceSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    userServices: userServicesReducer,
    userCustomers: userCustomersReducer,
    userInvoices: userInvoiceReducer,
    userCurrentService: userCurrentServiceReducer,

    customer: customerReducer,
    customerServices: customerServicesReducer,
    customerService: customerServiceReducer,
    customerServiceImage: customerServiceImageReducer,
    customerInvoice: customerInvoiceReducer,
    customerInvoices: customerInvoicesReducer,
  },
});
