import { createSlice } from '@reduxjs/toolkit'

export const userCustomersSlice = createSlice({
  name: 'userCustomers',
  initialState: {
    value: []
  },
  reducers: {
    setusercustomers: (state, action) => {
      state.value = action.payload
    },
    removeusercustomers: (state) => {
      state.value = []
    }
  }, 
})

// Action creators are generated for each case reducer function
export const { setusercustomers, removeusercustomers } = userCustomersSlice.actions
export default userCustomersSlice.reducer