// import React, { useEffect, useRef, useState } from 'react'
// import { useAuth } from '../components/Auth/Auth'
import Layout from "../components/Templates/Layout";
// import Timer from '../components/Timer/Timer'
// import { BsFillFileEarmarkPersonFill } from 'react-icons/bs'
// import { IoClose, IoNotificationsCircleSharp } from 'react-icons/io5'
// import { IoIosWarning } from 'react-icons/io'
import { useNavigate } from "react-router-dom";
import { setcustomerservice } from "../features/customers/customerServiceSlice";
import { useSelector } from "react-redux";
import ListCardItem from "../components/Cards/ListCardItem";
import "./servicelog.scss";
import { useDispatch } from "react-redux";
import { MdKeyboardArrowLeft } from "react-icons/md";
export default function ServiceLogs() {
  const userServices = useSelector((state) => state.userServices.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handleSelection = () => {
  //   console.log(selectedRef.current.value)
  //   console.log(userCustomers[selectedRef.current.value])
  //   setCurrentCustomer(userCustomers[selectedRef.current.value])
  // }

  // const closeTimer = () => {
  //   setServiceStarted(false)
  // }

  // const beginService = () => {
  //   setServiceStarted(true)
  //   const startTime = new Date().toLocaleString()
  //   setServiceStartTime(startTime)
  // }

  const handleServiceClick = (service) => {
    dispatch(setcustomerservice(service));
    navigate(`/service/${service.id}`);
  };
  // const toggleForm = () => {
  //   setShowNewCustomerForm(false)
  // }

  return (
    <Layout>
      <div className="general-page">
        <div className="page-title">
          <div>
            <div className="title-nav">
              <MdKeyboardArrowLeft />
              <h4 onClick={() => navigate("/dashboard")}>Dashboard</h4>
            </div>
            <h1>Service Logs</h1>
          </div>
          {/* <button className="action" onClick={"/"}>
            New Service
          </button> */}
        </div>

        <input type="text" placeholder="Search Services..." />

        {/* Pagination required */}
      </div>
      {/* {!serviceStarted && userCustomers?.length > 0 ?
          <div>
          <select ref={selectedRef} onChange={handleSelection}>
            <option>Select a Customer</option>
            {userCustomers.map((customer, index)=> (
            <option value={index} key={index}>{`${customer.firstname} ${customer.lastname}`}</option>
            ))}
          </select>
          </div> : ""} */}
      {/* </div> */}
      {userServices.map((service, index) => (
        <div
          data={service.id}
          key={index}
          onClick={() => handleServiceClick(service)}
        >
          <ListCardItem
            data={service.id}
            key={index}
            valueOne={`${service.firstname} ${service.lastname}`}
            valueTwo={``}
            valueThree={`${service.id}`}
            valueFour={`Hours: ${service.hours}`}
            type={`service-log`}
          />
        </div>
      ))}
      {/* {userServices.length > 0 ? 
              <div data={currentCustomer.id} key={currentCustomer.id} onClick={()=> handleServiceClick(currentCustomer.id)}>
                <div className='customer-record' >
                  <div><BsFillFileEarmarkPersonFill /></div>
                  <div>
                    <span>{currentCustomer.firstname} {currentCustomer.lastname}</span>
                    <span>{currentCustomer.city} {currentCustomer.state}</span>
                  </div>
                  <div><span>Service Logs: 0</span></div>
                </div>
              </div> : ""} */}
      {/*    
              {currentCustomer && !serviceStarted ? <button onClick={beginService}>Start Service</button> : ""}
            {serviceStarted ? 
            <div>
              <div className='alert-container'>
                <div className='header'>
                  <div><IoNotificationsCircleSharp />Notification</div>
                  <div><IoClose /></div>
                </div>
                <div className='message'>
                You have just started services for this customer. Currently this is not being 
                tracked but will be shortly. Check back frequently for changes. A timer will start
                here. A log of this will not be saved until you have ended the service and added a brief description 
                of what you did.
                </div>
              </div>
              <div className='alert-container'>
                <div className='header'>
                  <div><IoIosWarning />Warning</div>
                  <div><IoClose /></div>
                </div>
                <div className='message'>
                While tracking hours DO NOT close this app or this page, you can minimize or shut off your phone.
                </div>
              </div>
              <span className='alert'>
              </span>
              <Timer startTime={serviceStartTime} closeTimer={closeTimer} customer={currentCustomer}/>
            </div>
             : userCustomers.length > 0 ? "" : <button onClick={()=> setShowNewCustomerForm(true)}>Add a Customer</button>}
             {showNewCustomerForm ? <AddCustomerForm toggleForm={toggleForm} /> : ""}
      </div> */}
    </Layout>
  );
}
