import React from 'react'
import Layout from '../components/Templates/Layout'
import icon from '../Images/ss_logo_long_v1.png'
import './updates.scss'
import { useAuth } from './../components/Auth/Auth';

export default function Updates() {
  return (
    <Layout>
        <div className="updates">
        <h3>View Updates</h3>
        <br />
        <p className='header'>Latest Update</p>
        <ul>
        <li className='header'>01/14/2023</li>
        </ul>
        <p>Add Account page (no functionality)</p>
        <p>Add Documentation page (under development)</p>
        <p>Removed broken links, now direct to UD page</p>
        <p>Added link to website for Policies and Partners</p>
        <p className='header'>Upcoming Updates</p>
        <p>Service Logging Adjustments</p>
        <p>Ability to save and print invoices</p>
        <p>Daily Report Generator - will generate all reports for day</p>
        <p>Invoice Search Capabilities</p>
        <p>Ability to download from Google Play Store</p>
        <p>Offline Mode and Saving</p>
        <p>Employee Registry</p>
        <p>Pay rates for employee registry</p>
        <p>Reports for Employees and tracking</p>
        <p>Premium Membership purchasing capabilities</p>
        <p>Task Manager</p>
        <p>Bill Viewer</p>
        <p className='header'>Previous Update</p>
        <ul>
        <li className='header'>01/08/2023</li>
        </ul>
        <p>Added invoices page, feature rolling out soon</p>
        <p>UI Updates (More to follow)</p>
        

        <p className='header'>Get Suggestions?</p>
        <p>Email me @ <a href="mailto:lauer.chris1@gmail.com">lauer.chris1@gmail.com</a></p>
        </div>
        <div className="updates">
        <ul>
        <li className='header'>12/5/2022</li>
        </ul>
        <img src={icon} alt={`navbar service stash logo`}/>
        <p className='header'>Application Icon</p>
        <p>This is the proposed application icon, most likely will change along with the application 
            name
        </p>
        <p>Added ability to add new customers</p>
        <p>Added ability to view specific customers</p>
        <p>Added All Customers, Updates, and Premium pages</p>
        <p>Fixed Various Bugs</p>
        <p className='header'>Upcoming Updates</p>
        <p>Ability to log services, customer specific</p>
        <p>Ability to view service logs, customer specific</p>
        <p>Ability to updates and delete customers</p>
        <p>Ability to download from Google Play Store</p>
        <p>Ability to download from Website</p>
        <p>Offline Mode and Saving</p>
        <p className='header'>Get Suggestions?</p>
        <p>Email me @ <a href="mailto:lauer.chris1@gmail.com">lauer.chris1@gmail.com</a></p>
        </div>
    </Layout>
  )
}
