import { Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';

export const ProtectedRoute = ({ children }) => {
const user = useSelector(state => state.user.value)
  if (!user) {
    console.log(user)
    return <Navigate to="/" />;
  }
  return children;
};