import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Templates/Layout";
import { MdOutlineContactPage, MdKeyboardArrowLeft } from "react-icons/md";
import DeleteClient from "../../components/Alerts/DeleteClient";
import "./customer.scss";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../components/Auth/Auth";
import ListCardItem from "../../components/Cards/ListCardItem";
import NewStandardAlert from "../../components/Alerts/NewStandardAlert";
import { EditCustomerForm } from "./EditCustomerForm";
import { update } from "../../features/user/userSlice";

export default function Index() {
  const dispatch = useDispatch();
  const params = useParams();
  const customerData = useSelector((state) => state.customer.value);
  const { fetchServiceDetails, beginService, updateCustomer } = useAuth();
  const [showCustomerDeleteAlert, setShowCustomerDeleteAlert] = useState(false);
  const [serviceInitialized, setServiceInitialized] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const customerServices = useSelector((state) => state.customerServices.value);
  const navigate = useNavigate();
  const handleSelection = (serviceId) => {
    navigate(`/service/${serviceId}`);
  };
  const handleDelete = (id) => {
    setShowCustomerDeleteAlert(true);
  };
  const closeCustomerDeleteAlert = () => {
    setShowCustomerDeleteAlert(false);
    console.log("closing alert");
  };
  const initiateService = () => {
    setServiceInitialized(true);
  };
  const startService = async () => {
    console.log("Called");
    let service = await beginService(customerData);
    await fetchServiceDetails(service.service_id);
    navigate(`/services/${service.service_id}`);
  };

  const updateEdittedCustomer = async (details) => {
    await updateCustomer(details);
    setShowEditForm(false);
  };

  useEffect(() => {
    console.log("update");
  }, [customerData]);

  return (
    <Layout>
      {showCustomerDeleteAlert && (
        <DeleteClient customer={params.id} close={closeCustomerDeleteAlert} />
      )}
      {serviceInitialized && (
        <NewStandardAlert
          error={false}
          title={`Start Service`}
          submit={startService}
          close={() => setServiceInitialized(false)}
          successBtn={`Continue`}
          successMessage={`Are you sure you want to start service?`}
          showCancel={true}
        />
      )}

      <div className="general-page">
        <div className="page-title">
          <div>
            <div className="title-nav" onClick={() => navigate("/customers")}>
              <MdKeyboardArrowLeft />
              <h4>Customers</h4>
            </div>
            <h1>Customer Detail</h1>
          </div>
          <button className="action" onClick={initiateService}>
            Start Service
          </button>
        </div>
        {showEditForm && (
          <EditCustomerForm
            customerData={customerData}
            submit={updateEdittedCustomer}
          />
        )}

        <div className="page-section-head-one">
          <div>
            {customerData.firstname} {customerData.lastname}
          </div>
          <ul>
            <li>
              <span className="label">Service Logs: </span>
              {customerServices.length}
            </li>
          </ul>
          <div>
            <MdOutlineContactPage />
          </div>
        </div>
        <div className="page-section-content-one">
          <ul>
            {customerData.suite ? <li>{customerData.suite}</li> : ""}
            <li>{customerData.street}</li>
            <li>
              {customerData.city}, {customerData.state}
            </li>
          </ul>
        </div>
        <div className="page-section-content-one">
          <ul>
            <li>
              <span className="bold">Phone:</span>
              {customerData.phone},
            </li>
            <li>
              <span className="bold">Email:</span>
              {customerData.email}
            </li>
          </ul>
        </div>
        <div className="actions-wrapper">
          <button
            className="action-style-two"
            onClick={() => setShowEditForm(true)}
          >
            Edit
          </button>
          <button className="warning" onClick={handleDelete}>
            Delete
          </button>
        </div>
        <div className="new-section">
          <h2>Recent Services</h2>
        </div>
        {customerServices.length > 0 &&
          customerServices.map((service, index) => (
            <div onClick={() => handleSelection(service.id)}>
              <ListCardItem
                data={service.id}
                key={index}
                valueOne={`${service.firstname} ${service.lastname}`}
                valueTwo={``}
                valueThree={`${service.id}`}
                valueFour={`Hours: ${service.hours}`}
                type={`service-log`}
              />
            </div>
          ))}
      </div>
    </Layout>
  );
}
