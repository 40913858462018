import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import ServiceLogs from "./pages/ServiceLogs";
import Customers from "./pages/Customers/Customers";
import Updates from "./pages/Updates";
import Premium from "./pages/Premium";
import Service from "./pages/Service/Service";
import Inventories from "./pages/Inventories";
import MyInvoice from "./pages/MyInvoice";
import Employees from "./pages/Employees";
import Documentation from "./pages/Documentation";
import AddCustomer from "./pages/Customer/AddCustomer";
import LogService from "./pages/LogService";
import AuthProvider from "./components/Auth/Auth";
import Login from "./pages/Login";
import { ProtectedRoute } from "./components/ProtectedRoutes/ProtectedRoute";
import MyAccount from "./pages/Account/MyAccount";
import MyBusiness from "./pages/Account/MyBusiness";
import ActiveService from "./pages/Services/ActiveService";
import Inventory from "./pages/Inventory/Inventory";
import { Calender } from "./pages/Calender/Calender";
import NewInvoice from "./pages/Invoices/NewInvoice";
import MyInvoices from "./pages/Invoices/MyInvoices";
import Customer from "./pages/Customer/Customer";
import AddInventory from "./pages/Inventory/AddInventory";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/updates" element={<Updates />} />
          <Route path="/premium" element={<Premium />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route
            path="/account/myaccount"
            element={
              <ProtectedRoute>
                <MyAccount />
              </ProtectedRoute>
            }
          />
          <Route
            path="/account/mybusiness"
            element={
              <ProtectedRoute>
                <MyBusiness />
              </ProtectedRoute>
            }
          />
          <Route
            path="/calender"
            element={
              <ProtectedRoute>
                <Calender />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/inventories"
            element={
              <ProtectedRoute>
                <Inventories />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/invoices"
            element={
              <ProtectedRoute>
                <MyInvoices />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/invoices/:id"
            element={
              <ProtectedRoute>
                <MyInvoice />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/invoices/new"
            element={
              <ProtectedRoute>
                <NewInvoice />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/employees"
            element={
              <ProtectedRoute>
                <Employees />
              </ProtectedRoute>
            }
          />
          <Route
            path="/myservicelogs"
            element={
              <ProtectedRoute>
                <ServiceLogs />
              </ProtectedRoute>
            }
          />
          <Route
            path="/services/:id"
            element={
              <ProtectedRoute>
                <ActiveService />
              </ProtectedRoute>
            }
          />
          <Route
            path="/service/create"
            element={
              <ProtectedRoute>
                <LogService />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers"
            element={
              <ProtectedRoute>
                <Customers />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customers/addcustomer"
            element={
              <ProtectedRoute>
                <AddCustomer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/customer/:id"
            element={
              <ProtectedRoute>
                <Customer />
              </ProtectedRoute>
            }
          />
          <Route
            path="/service/:id"
            element={
              <ProtectedRoute>
                <Service />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory"
            element={
              <ProtectedRoute>
                <Inventory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/inventory/add"
            element={
              <ProtectedRoute>
                <AddInventory />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
