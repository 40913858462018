import React, { useState } from 'react';
import { useAuth } from '../Auth/Auth';
const XLSX = require('xlsx') ;

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const { setCurrentInventory } = useAuth()
  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileSubmit = () => {
    console.log(file)
    if (!file) {
      console.log('Please select a file to upload.');
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, {type: 'array'});

      workbook.SheetNames.forEach( sheetName => {
        const worksheet = workbook.Sheets[sheetName];
        const headers = [];
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        // Get the column headers
        for (let C = range.s.c; C <= range.e.c; ++C) {
          const cell = worksheet[XLSX.utils.encode_cell({ c: C, r: 0 })];
          if (!cell) continue;
          headers[C] = cell.v.toLowerCase();
        }
        // check if headers includes id, description, price, and quantity
        if (headers.includes("id") && headers.includes("description") && headers.includes("price") && headers.includes("quantity")) {
          console.log("File includes required headers : 'id', 'description', 'price', and 'quantity' ");
          const data = [];
          for (let R = 1; R <= range.e.r; ++R) {
            const row = {};
            for (let C = range.s.c; C <= range.e.c; ++C) {
              const cell = worksheet[XLSX.utils.encode_cell({ c: C, r: R })];
              if (!cell) continue;
              row[headers[C]] = cell.v;
            }
            data.push(row);
          }
          console.log('Data:', data);
          if(data.length < 300) {
            setCurrentInventory(data)
          } else {
            alert(`Free tiers are only able to upload a maximum of 300 items`)
          }
        } else {
          alert("File doesn't includes the required headers : 'id', 'description', 'price', and 'quantity' ");
        }
      });
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
        <p>There are required headers for this to work. The required headers are: `id`,`description`,`name`,`price`, `qty`</p>
        <p>Its beneficial if you documents includes these headers: `cost`, and `name`. Cost refers 
            to the amount you paid for the item, this helps in calulculating profits.
        </p>
      <input type="file" onChange={handleFileUpload} />
      <button onClick={handleFileSubmit}>Upload</button>
    </div>
  );
};

export default FileUpload;