import React from "react";
import { Link } from "react-router-dom";
import "./dashnav.scss";

export default function DashNav() {
  return (
    <div className="dash-nav">
      <ul>
        {/* <li>
          <Link to={`#`}>Accounting</Link>
        </li> */}
        {/* <li>
          <Link to={`/calender`}>Calender</Link>
        </li> */}
        <li>
          <Link to={`/dashboard/invoices`}>Invoices</Link>
        </li>
        <li>
          <Link to={`/myservicelogs`}>Services</Link>
        </li>
        <li>
          <Link to={`/customers`}>Customers</Link>
        </li>
        {/* <li>
          <Link to={`#`}>My Rates</Link>
        </li> */}
        <li>
          <Link to={`/inventory`}>Inventory</Link>
        </li>
        {/* <li>
          <Link to={`#`}>Contacts</Link>
        </li> */}
      </ul>
    </div>
  );
}
