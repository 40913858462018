import React, { useEffect } from 'react';
import { useAuth } from '../Auth/Auth';
import './invoice-style.scss'

function Invoice() {
  // const { currentInvoice } = useAuth()

  const currentInvoice = {
    id: "123456",
    date: "2022-06-01",
    dueDate: "2022-06-15",
    customer: {
      name: "John Smith",
      addressOne: "123 Main St", 
      addressTwo: "Anytown USA 12345",
      phone: 123-456-7890
    },
    items: [
      {
        description: "Widget A",
        quantity: 2,
        price: 25.00
      },
      {
        description: "Widget B",
        quantity: 1,
        price: 50.00
      }
    ],
    total: 100.00,
    paid: false
  }
  
  return (
    <div className='invoice-page'>
      <div className='invoice-header'>
        <div className='invoice-head-left'>
          <h1>Invoice</h1>
        </div>
        <div className='invoice-head-right'>
          <div>
          <span>Invoice #</span>
          <span>{currentInvoice.id}</span>
          </div>
        </div>
      </div>
      <div className='contact-details'>
        <div className='contact-info'>
          <span className='from'>From</span>
          <span className='sender'>User</span>
          <span className='email'>email@email.com</span>
          <span className='address'>123 Not a Street</span>
          <span className='address'>New York, NY 12345</span>
          <span className='phone'>123-456-7890</span>
        </div>
        <div className='contact-info'>
        <span className='from'>To</span>
          <span className='sender'>{currentInvoice.customer.name}</span>
          <span className='email'>email@email.com</span>
          <span className='address'>{currentInvoice.customer.addressOne}</span>
          <span className='address'>{currentInvoice.customer.addressTwo}</span>
          <span className='phone'>{currentInvoice.customer.phone}</span>
        </div>
      </div>
      <table>
          <thead>
          <th>Description</th>
          <th>Price</th>
          <th>Qty</th>
          <th>Amount</th>
          </thead>
          <tbody>
              {currentInvoice.items.map(item => (
              <tr>
              <td>{item.description}</td>
              <td>${item.price.toFixed(2)}</td>
              <td>{item.quantity}</td>
              <td>${(item.price * item.quantity).toFixed(2)}</td>
              </tr>
              ))}
          </tbody>
        </table>
        <div className='totals'>
        <span>Subtotal:<span className='value'>$0.00</span></span>
        <span>Discount:<span className='value'>$0.00</span></span>
        <span>Subtital Less Dsicount:<span className='value'>$0.00</span></span>
        <span>Tax Rate:<span className='value'>$0.00</span></span>
        <span>Total Taxes:<span className='value'>$0.00</span></span>
        <span>Shipping:<span className='value'>$0.00</span></span>
        <span>Balance Due:<span className='value'>$100.00</span></span>
        </div>
    </div>
  );
}

export default Invoice;
