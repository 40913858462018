import { createSlice } from '@reduxjs/toolkit'

export const customerInvoiceSlice = createSlice({
  name: 'customerInvoice',
  initialState: {
    value: []
  },
  reducers: {
    setcustomerinvoice: (state, action) => {
      state.value = action.payload
    },
  }, 
})

// Action creators are generated for each case reducer function
export const { setcustomerinvoice } = customerInvoiceSlice.actions
export default customerInvoiceSlice.reducer