import { createSlice } from '@reduxjs/toolkit'

export const customerServicesSlice = createSlice({
  name: 'customerServices',
  initialState: {
    value: []
  },
  reducers: {
    setcustomerservices: (state, action) => {
      state.value = action.payload
    },
  }, 
})

// Action creators are generated for each case reducer function
export const { setcustomerservices } = customerServicesSlice.actions
export default customerServicesSlice.reducer