import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { IoHandLeft } from 'react-icons/io5'
import { useAuth } from "../../components/Auth/Auth";
import Layout from "../../components/Templates/Layout";
import "./account.scss";

export default function MyAccount() {
  const { user, updateUserInfo } = useAuth();
  const accountFormRef = useRef(null);
  const [showSaveHead, setShowSaveHead] = useState(false);
  const navigate = useNavigate();
  const handleEmailChange = (event) => {
    event.preventDefault();
    setShowSaveHead(!showSaveHead);
  };
  const business_nameRef = useRef(null);
  const business_emailRef = useRef(null);
  const business_streetRef = useRef(null);
  const business_suiteRef = useRef(null);
  const business_cityRef = useRef(null);
  const business_stateRef = useRef(null);
  const business_zipRef = useRef(null);
  const business_phoneRef = useRef(null);

  const handleSave = (event) => {
    event.preventDefault();
    let target = event.currentTarget.id;
    if (target === "business address") {
      let business_details = {
        // business_name: business_nameRef.current.target.value,
        business_street: business_streetRef.current.target.value,
        business_suite: business_suiteRef.current.target.value,
        business_state: business_stateRef.current.target.value,
        business_city: business_cityRef.current.target.value,
        // business_phone: business_phoneRef.current.target.value,
        // business_email: business_emailRef.current.target.value,
      };
      console.log(business_details);
      // updateUserInfo(business_details)
    }
    if (target === "business") {
    }
    if (target === "business-close") {
    }
    if (target === "account-close") {
    }
  };

  return (
    <Layout>
      <div className="my-account-page">
        <div className="account-navigation-container">
          <h4>My Business Details</h4>
          <div>
            <button onClick={() => navigate("/account/myaccount")}>
              Account{" "}
            </button>
            <button className="active">Business </button>
            <button>Preferences </button>
          </div>
        </div>
        <div className="details-container">
          <div className="title-head">
            <h4>This page is under development</h4>
          </div>
          <div className="danger-container" ref={accountFormRef}>
            <div>
              <p>Informating and changes on here may not work properly</p>
            </div>
          </div>
        </div>
        <div className="details-container">
          <div className="title-head">
            <h4>Business Information</h4>
          </div>
          <div className="form-container" ref={accountFormRef}>
            <span>Business Name</span>
            <input
              type="text"
              placeholder={user?.business_details.business_name}
              ref={business_nameRef}
              onChange={handleEmailChange}
            />
            <span>Business Email</span>
            <input
              type="text"
              placeholder={user?.business_details.business_email}
              ref={business_emailRef}
              onChange={handleEmailChange}
            />
            <span>Business Phone</span>
            <input
              type="text"
              placeholder={user?.business_details.business_email}
              ref={business_phoneRef}
              onChange={handleEmailChange}
            />
          </div>
          <div className="title-foot">
            <div className="actions">
              <button id="account-close" onClick={handleSave}>
                Cancel
              </button>
              <button className="save" id="account" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="details-container">
          <div className="title-head">
            <h4>Business Address</h4>
          </div>
          <div className="form-container" ref={accountFormRef}>
            <span>Street</span>
            <input
              type="text"
              placeholder={user?.business_details.business_street}
              ref={business_streetRef}
              onChange={handleEmailChange}
            />
            <span>Suite / Apt</span>
            <input
              type="text"
              placeholder={user?.business_details.business_suite}
              ref={business_suiteRef}
              onChange={handleEmailChange}
            />
            <span>City</span>
            <input
              type="text"
              placeholder={user?.business_details.business_city}
              ref={business_cityRef}
              onChange={handleEmailChange}
            />
            <span>State</span>
            <input
              type="text"
              placeholder={user?.business_details.business_state}
              ref={business_stateRef}
              onChange={handleEmailChange}
            />
            <span>Zip</span>
            <input
              type="text"
              placeholder={user?.business_details.business_zip}
              ref={business_zipRef}
              onChange={handleEmailChange}
            />
          </div>
          <div className="title-foot">
            <div className="actions">
              <button id="account-close" onClick={handleSave}>
                Cancel
              </button>
              <button className="save" id="account" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        </div>
        <section className="business-details"></section>
      </div>
    </Layout>
  );
}
