import React, { useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
// import { BsFillFileEarmarkPersonFill } from 'react-icons/bs'
import "./dashboard.scss";
import DashboardCard from "../../components/Dashboard/DashboardCard";
import DashNav from "../../components/Dashboard/DashNav/DashNav";
import UpdateAccountAlert from "../../components/Alerts/UpdateAccountAlert";
import { useDispatch, useSelector } from "react-redux";
import ListCardItem from "../../components/Cards/ListCardItem";
import ActiveServiceCard from "../Services/ActiveServiceCard";
import { setcustomerservice } from "../../features/customers/customerServiceSlice";
import Layout from "./../../components/Templates/Layout";
import { useAuth } from "./../../components/Auth/Auth";
import { setusercurrentservice } from "../../features/user/userCurrentServiceSlice";

export default function Dashboard() {
  const dispatch = useDispatch();
  const { fetchServiceDetails } = useAuth();
  const userCustomers = useSelector((state) => state.userCustomers.value);
  const userServices = useSelector((state) => state.userServices.value);
  const userInvoices = useSelector((state) => state.userInvoices.value);

  const navigate = useNavigate();
  const handleServiceClick = (service) => {
    dispatch(setcustomerservice(service));
    navigate(`/service/${service.service_id}`);
  };

  const selectService = async (service, index) => {
    console.log(service);
    let test = await fetchServiceDetails(service.service_id);
    console.log(test);
    navigate(`/services/${service.service_id}`);
  };

  const checkForOngoingServices = () => {
    let ongoingServices = false;
    userServices.map((service) => {
      if (!service.stoppedAt) {
        ongoingServices = true;
      }
    });
    return ongoingServices;
  };

  return (
    <Layout>
      {userServices ? (
        <div className="dashboard">
          <UpdateAccountAlert />
          {userServices.length > 0 ? (
            <div className="started-services-wrapper">
              <div className="content">
                <div className="ongoing-service-card">
                  {checkForOngoingServices() && (
                    <div className="head">
                      <span>Ongoing Service</span>
                      <span className="manage">
                        <div className="animated-div"></div>
                      </span>
                    </div>
                  )}

                  {userServices.map((service, index) => {
                    console.log(service);
                    if (service.stoppedAt === null) {
                      return (
                        <div
                          key={service.service_id}
                          onClick={() => selectService(service, index)}
                        >
                          <ActiveServiceCard service={service} />
                        </div>
                      );
                    } else {
                      return "";
                    }
                  })}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="card-wrapper">
            {/* <DashboardCard title={`total earnings`} details={`$0.00`} buttonOneAction={`/`} buttonOneName={`View Earnings`} />
        <DashboardCard title={`daily earning`} details={`$0.00`} buttonOneAction={`/`} buttonOneName={`Accounting`} /> */}
            <div onClick={() => navigate("/customers")}>
              <DashboardCard
                title={`customer count`}
                details={userCustomers.length}
                buttonOneAction={`customers`}
                buttonOneName={`My Customers`}
              />
            </div>
            <div onClick={() => navigate("/myservicelogs")}>
              <DashboardCard
                title={`total services`}
                details={userServices.length}
                buttonOneAction={`myservicelogs`}
                buttonOneName={`My Services`}
              />
            </div>
            <div onClick={() => navigate("/dashboard/employees")}>
              <DashboardCard
                title={`Empoyees Coming soon`}
                details={0}
                buttonOneAction={`/dashboard/employees`}
                buttonOneName={`My Employees`}
              />
            </div>
            <div onClick={() => navigate("/dashboard/invoices")}>
              <DashboardCard
                title={`Invoices Coming Soon`}
                details={userInvoices.length}
                buttonOneAction={``}
                buttonOneName={`My Invoices`}
              />
            </div>
          </div>
          <DashNav />

          {userServices.length > 0 && (
            <div className="recent-service-log-container">
              <h5>Recent Services</h5>
              {userServices.map((service, index) => {
                if (service.stoppedAt !== null) {
                  return (
                    <div
                      data={service.service_id}
                      key={service.service_id}
                      onClick={() => handleServiceClick(service)}
                    >
                      <ListCardItem
                        data={service.service_id}
                        key={index}
                        valueOne={`${service.firstname} ${service.lastname}`}
                        valueTwo={``}
                        valueThree={`${service.service_id}`}
                        valueFour={`Hours: ${service.hours}`}
                        type={`service-log`}
                      />
                    </div>
                  );
                } else {
                  return "";
                }
              })}
            </div>
          )}
        </div>
      ) : (
        "Not logged in"
      )}
    </Layout>
  );
}
