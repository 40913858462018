import React from "react";
import "./listcarditem.scss";
import {
  MdInsertDriveFile,
  MdContactPage,
  MdOutlineUploadFile,
  MdOutlineContactPage,
} from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa";
export default function ListCardItem(props) {
  let type = props.type;
  let valueOne = props.valueOne;
  let valueTwo = props.valueTwo;
  let valueThree = props.valueThree;
  let valueFour = props.valueFour;

  if (type === "service-log") {
    return (
      <div className="list-card-item">
        <div>
          <MdOutlineUploadFile className="log" />
        </div>
        <div>
          <div className="card-details">
            <div>{valueOne}</div>
            <div>{valueTwo}</div>
          </div>
          <div className="card-details">
            <div>{valueThree}</div>
            <div>{valueFour}</div>
          </div>
        </div>
      </div>
    );
  }
  if (type === "customer-log") {
    return (
      <div className="list-card-item">
        <div>
          <MdOutlineContactPage />
        </div>
        <div>
          <div className="card-details">
            <div>{valueOne}</div>
            <div>{valueTwo}</div>
          </div>
          <div className="card-details">
            <div>{valueThree}</div>
            <div>{valueFour}</div>
          </div>
        </div>
      </div>
    );
  }
  if (type === "invoice") {
    return (
      <div className="list-card-item">
        <div>
          <FaFileInvoiceDollar className="invoice-icon-style" />
        </div>
        <div>
          <div className="card-details">
            <div>{valueOne}</div>
            <div>{valueTwo}</div>
          </div>
          <div className="card-details">
            <div>{valueThree}</div>
            <div>{valueFour}</div>
          </div>
        </div>
      </div>
    );
  }
  return <div>Cards</div>;
}
