import { createSlice } from '@reduxjs/toolkit'

export const userInvoicesSlice = createSlice({
  name: 'userInvoices',
  initialState: {
    value: []
  },
  reducers: {
    setuserinvoices: (state, action) => {
      state.value = action.payload
    },
    removeuserinvocies: (state) => {
      state.value = []
    }
  }, 
})

// Action creators are generated for each case reducer function
export const { setuserinvoices, removeuserinvocies } = userInvoicesSlice.actions
export default userInvoicesSlice.reducer