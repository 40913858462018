import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "./Auth/Auth";
import { FcGoogle } from "react-icons/fc";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import logo from "../Images/ss_logo_stack_v1_128.png";
import "./loginform.scss";

export default function LoginForm() {
  const [loginForm, setLoginForm] = useState(true);
  const regEmailRef = useRef(null);
  const regPasswordRef = useRef(null);
  const loginEmailRef = useRef(null);
  const loginPasswordRef = useRef(null);
  const { googleSignIn, emailAndPasswordLogin, registerNewUser } = useAuth();

  const register = (event) => {
    event.preventDefault();
    let email = regEmailRef.current.value;
    let password = regPasswordRef.current.value;
    registerNewUser(email, password);
  };

  const loginWithEmailandPassword = (event) => {
    event.preventDefault();
    let email = loginEmailRef.current.value;
    let password = loginPasswordRef.current.value;
    console.log(email);
    console.log(password);
    emailAndPasswordLogin(email, password);
  };

  const toggleForm = (event) => {
    event.preventDefault();
    setLoginForm(!loginForm);
  };

  return (
    <div className="login-page">
      {loginForm ? (
        <form className="login-form">
          <div className="register-button-wrapper" onClick={toggleForm}>
            <IoArrowBackCircleSharp className="back-svg" />
            Register
          </div>
          <img src={logo} alt="Servicestash App" />
          <label>Email</label>
          <input
            type="email"
            ref={loginEmailRef}
            placeholder="Enter a valid email"
          />
          <label>Password</label>
          <input
            type="password"
            ref={loginPasswordRef}
            placeholder="Enter password"
          />
          <Link to="#">Forgot Password?</Link>
          <button onClick={loginWithEmailandPassword} className="login">
            Login
          </button>
          <span>or</span>
          <button onClick={googleSignIn} className="google">
            <FcGoogle /> Login with Google
          </button>
        </form>
      ) : (
        <form className="login-form">
          <div className="register-button-wrapper" onClick={toggleForm}>
            <IoArrowBackCircleSharp className="back-svg" />
            Login
          </div>
          <img src={logo} alt={`service stash logo`} />
          <label>Email</label>
          <input
            type="email"
            ref={regEmailRef}
            placeholder="Enter a valid email"
          ></input>
          <label>Password</label>
          <input
            type="password"
            ref={regPasswordRef}
            placeholder="Enter password"
          ></input>
          <button onClick={register} className="login">
            Register
          </button>
          <span>or</span>
          <button onClick={googleSignIn} className="google">
            <FcGoogle /> Signup with Google
          </button>
        </form>
      )}
      <p>
        Servicestash is completely secured and powered by Google Cloud Services.
        Servicestash is a privately owned company, we reserve the right to ban
        any users who attempts to break, hack, alter, or exploit our
        application.
      </p>
    </div>
  );
}
