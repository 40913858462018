import React, { useState, useRef } from "react";

export const NewInvoiceItem = (props) => {
  const itemNameRef = useRef(null);
  const itemDescRef = useRef(null);
  const itemQtyRef = useRef(null);
  const itemPriceRef = useRef(null);
  const formRef = useRef();
  const [itemNameError, setItemNameError] = useState(null);
  const [itemQtyError, setItemQtyError] = useState(null);
  const [itemPriceError, setItemPriceError] = useState(null);
  const [itemTotal, setItemTotal] = useState("$0.00");

  const handleSave = (event) => {
    event.preventDefault();
    let details = {
      item_name: itemNameRef.current.value,
      item_desc: itemDescRef.current.value,
      item_qty: itemQtyRef.current.value,
      item_price: itemPriceRef.current.value,
      item_total: null,
      item_id: null,
    };
    let itemNameStatus = details.item_name === "" ? false : true;
    let itemQtyStatus = details.item_qty === "" ? false : true;
    let itemPriceStatus = details.item_price === "" ? false : true;

    !itemNameStatus ? setItemNameError("Name*") : setItemNameError(false);
    !itemQtyStatus ? setItemQtyError("Quantity*") : setItemQtyError(false);
    !itemPriceStatus ? setItemPriceError("Price*") : setItemPriceError(false);
    if (itemNameStatus && itemQtyStatus && itemPriceStatus) {
      // Calculate line total
      details.item_total = details.item_price * details.item_qty;
      details.item_id = Date.now();
      props.save(details);
      formRef.current.reset();
      setItemTotal(`$0.00`);
    } else {
      console.log("errors");
    }
  };

  const handleChange = () => {
    if (!itemQtyRef.current.value || !itemPriceRef.current.value) {
      console.log("cant calculate");
    } else {
      let total = itemQtyRef.current.value * itemPriceRef.current.value;
      setItemTotal(total.toFixed(2));
    }
  };
  return (
    <div className="invoice-items-container">
      <form ref={formRef}>
        <div className="invoice-line-item-wrapper">
          <div>
            {itemNameError ? (
              <span className="error">{itemNameError}</span>
            ) : (
              <span>Name</span>
            )}
            <input type="text" placeholder="Item Name" ref={itemNameRef} />
          </div>
          <div>
            <span>Description</span>
            <input
              type="text"
              placeholder="Item Description"
              ref={itemDescRef}
            />
          </div>
          <div>
            {itemQtyError ? (
              <span className="error">{itemQtyError}</span>
            ) : (
              <span>Quantity</span>
            )}
            <input
              type="number"
              placeholder="123"
              ref={itemQtyRef}
              onChange={handleChange}
            />
          </div>
          <div>
            {itemPriceError ? (
              <span className="error">{itemPriceError}</span>
            ) : (
              <span>Price</span>
            )}
            <input
              type="text"
              placeholder="123.00"
              ref={itemPriceRef}
              onChange={handleChange}
            />
          </div>
          <div>
            <span>Line Total</span>
            <label>{`${itemTotal}`}</label>
          </div>
          <div>
            <button className="blue" id="account" onClick={handleSave}>
              Add Item
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
