import React, { useState } from "react";
import Layout from "../../components/Templates/Layout";
import "./inventory.scss";

export default function AddInventory() {
  const [showAddItemForm, setShowAddItemForm] = useState(false);
  return (
    <Layout>
      <h4>Add Item</h4>
      <div className="desc">
        <label>Item Name</label>
        <input type="text" placeHolder="" />
        <label>Item Description</label>
        <textarea placeholder="Brief description of the item" />
        <label>Item Price</label>
        <p>Price the customer will pay</p>
        <input type="number" placeHolder="" />
        <label>Item Cost</label>
        <p>How much did it cost you?</p>
        <input type="number" placeHolder="" />
        <label>Item Serial / Part Number</label>
        <p>How much did it cost you?</p>
        <input type="number" placeHolder="" />
        <label>Quantity Available</label>
        <p>
          How many do you have? Unit measurements to be added later. For
          example, board ft, pounds, or tons.
        </p>
        <input type="number" placeHolder="" />
        <p>
          The item you enter will automatically be assigned a unique key. You
          can add a custom part number or serial number to refine searches.
        </p>
      </div>
      <button className="action">Save Item</button>
      <button className="grey">Cancel</button>
    </Layout>
  );
}
