import "./standardalert.scss";
export default function NewStandardAlert(props) {
  let error = props.error;
  let title = props.title;
  let warningMessage = props.warningMessage;
  let close = props.close;
  let submit = props.submit;
  let successBtn = props.successBtn;
  let successMessage = props.successMessage;
  let showCancel = props.showCancel;
  let errorTitle = props.errorTitle;
  //////////////////////////COPY BELOW FOR QUICK REFERENCE LAYOUT//////////////////////////////////
  //////////////////////////COPY BELOW FOR QUICK REFERENCE LAYOUT//////////////////////////////////
  //   <NewStandardAlert
  //   error={error}
  //   successMessage={serviceResponse.message}
  //   warningMessage={serviceResponse.message}
  //   title={`Service Started`}
  //   errorTitle={`Service Not Started`}
  //   close={closeServiceStartedAlert}
  //   submit={closeServiceStartedAlert}
  //   successBtn={`Continue`}
  //   showCancel={false}
  // />
  //////////////////////////COPY ABOVE FOR QUICK REFERENCE LAYOUT//////////////////////////////////
  //////////////////////////COPY ABOVE FOR QUICK REFERENCE LAYOUT//////////////////////////////////
  if (error) {
    return (
      <div className="add-customer-alert-wrapper">
        <div className="add-customer-alert">
          <div className="alert-head">
            <p>{errorTitle}</p>
          </div>
          <div className="content">
            <p>{warningMessage}</p>
          </div>
          <div className="button-container">
            <div></div>
            <button className="link" onClick={close}>
              cancel
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="add-customer-alert-wrapper">
        <div className="add-customer-alert">
          <div className="alert-head">
            <p>{title}</p>
          </div>
          <div className="content">
            <p>{successMessage}</p>
          </div>
          <div className="button-container">
            <button className="action" onClick={submit}>
              {successBtn}
            </button>
            {showCancel ? (
              <button className="link" onClick={close}>
                cancel
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
