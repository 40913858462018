import React from "react";
import { MdOutlineRestartAlt, MdEditNote } from "react-icons/md";
import { FaRegAddressCard, FaRegClock } from "react-icons/fa";
import { useSelector } from "react-redux";
// Inherits CSS from dashboard
export default function ActiveServiceCard(props) {
  const user = useSelector((state) => state.user.value);
  const service = props.service;

  function timeDifference(date) {
    var current = new Date();
    var previous = new Date(date);
    var diff = current - previous;
    var hours = Math.floor(diff / 1000 / 60 / 60);
    var minutes = Math.floor(diff / 1000 / 60) % 60;
    let convertedMinutes = minutes / 60;
    let totalTime = hours + convertedMinutes;
    let timeDifference = {
      hours: hours,
      minutes: minutes,
      minutesInDec: convertedMinutes.toFixed(2),
      totalTime: totalTime.toFixed(2),
    };
    return totalTime;
  }

  return (
    <div className="card-content">
      <ul>
        <li>
          <FaRegAddressCard />
          {service.firstname} {service.lastname}
        </li>
      </ul>
      <ul>
        <li>
          <FaRegClock />
          {timeDifference(service.startedAt).toFixed(2)} Hours
        </li>
      </ul>
      <MdEditNote />
    </div>
  );
}
