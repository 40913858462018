import { createSlice } from "@reduxjs/toolkit";

export const customerServiceImageSlice = createSlice({
  name: "customerServiceImage",
  initialState: {
    value: [],
  },
  reducers: {
    setcustomerserviceimage: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setcustomerserviceimage } = customerServiceImageSlice.actions;
export default customerServiceImageSlice.reducer;
