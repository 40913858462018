import { createSlice } from '@reduxjs/toolkit'

export const userServicesSlice = createSlice({
  name: 'userServices',
  initialState: {
    value: []
  },
  reducers: {
    setuserservices: (state, action) => {
      state.value = action.payload
    },
    removeuserservices: (state) => {
      state.value = []
    }
  }
})

// Action creators are generated for each case reducer function
export const { setuserservices, removeuserservices } = userServicesSlice.actions

export default userServicesSlice.reducer