import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Templates/Layout";
import "./addcustomer.scss";
import { useNavigate, useLocation } from "react-router-dom";
import AddCustomerAlert from "./../../components/Alerts/AddCustomerAlert";
/////////////////////////////TO DO/////////////////////////////
///////////////////////////////////////////////////////////////
////////////////////CLEAR FORM ON SUBMISSION///////////////////

export default function AddCustomer() {
  const firstnameRef = useRef(null);
  const lastnameRef = useRef(null);
  const streetRef = useRef(null);
  const suiteRef = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const zipRef = useRef(null);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);
  const [successStatus, setSuccessStatus] = useState(false);
  const [customerDetails, setCustomerDetails] = useState(null);
  const location = useLocation();
  const saveCustomer = (event) => {
    event.preventDefault();
    let newCustomer = {
      firstname: firstnameRef.current.value,
      lastname: lastnameRef.current.value,
      street: streetRef.current.value,
      suite: suiteRef.current.value,
      city: cityRef.current.value,
      state: stateRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      zip: zipRef.current.value,
      service_count: 0,
      created_at: Date.now(),
    };
    setCustomerDetails(newCustomer);
    // Form error handling
    if (!newCustomer.firstname) {
      setErrorMessage("You need to enter the customers firstname");
      setShowAlert(true);
      return;
    }
    if (!newCustomer.phone) {
      setErrorMessage("You need to enter the customers phone number");
      setShowAlert(true);
      return;
    }
    if (!newCustomer.lastname || !newCustomer.email) {
      setWarningMessage(true);
    }
    if (
      !newCustomer.street ||
      !newCustomer.city ||
      !newCustomer.state ||
      !newCustomer.street
    ) {
      setWarningMessage(true);
    }
    if (newCustomer.firstname && newCustomer.phone) {
      setSuccessStatus(true);
      setShowAlert(true);
    }
    // alert("new customer added")
    // addNewCustomer(newCustomer)
  };

  const cancel = (event) => {
    event.preventDefault();
    navigate("/customers");
  };

  const handleChange = (event) => {
    event.preventDefault();
  };

  const toggleAlert = () => {
    setShowAlert(!showAlert);
  };
  useEffect(() => {
    if (location.pathname !== "/customers/addcustomer") setShowAlert(false);
  }, [location]);
  return (
    <Layout>
      {showAlert ? (
        <AddCustomerAlert
          error={errorMessage}
          success={successStatus}
          warningMessage={warningMessage}
          close={toggleAlert}
          customer={customerDetails}
        />
      ) : (
        ""
      )}
      <div className="add-customer">
        <div className="details-container">
          <div className="title-head">
            <h4>Customer Information</h4>
          </div>
          <div className="form-container">
            <span>Firstname **</span>
            <input
              type="text"
              placeholder={`John`}
              ref={firstnameRef}
              onChange={handleChange}
            />
            <span>Lastname</span>
            <input
              type="text"
              placeholder={`Smith`}
              ref={lastnameRef}
              onChange={handleChange}
            />
            <span>Email</span>
            <input
              type="text"
              placeholder={`johnsmith@gmail.com`}
              ref={emailRef}
              onChange={handleChange}
            />
            <span>Phone **</span>
            <input
              type="text"
              placeholder={`123-456-7890`}
              ref={phoneRef}
              onChange={handleChange}
            />
          </div>
          <div className="section-head">
            <h4>Address Information</h4>
          </div>
          <div className="form-container">
            <span>Street Address</span>
            <input
              type="text"
              placeholder={`John`}
              ref={streetRef}
              onChange={handleChange}
            />
            <span>Apt / Suite</span>
            <input
              type="text"
              placeholder={`Smith`}
              ref={suiteRef}
              onChange={handleChange}
            />
            <span>City</span>
            <input
              type="text"
              placeholder={`Clarion`}
              ref={cityRef}
              onChange={handleChange}
            />
            <span>State</span>
            <input
              type="text"
              placeholder={`PA`}
              ref={stateRef}
              onChange={handleChange}
            />
            <span>Zip</span>
            <input
              type="text"
              placeholder={`12345`}
              ref={zipRef}
              onChange={handleChange}
            />
          </div>
          <div className="title-foot">
            <div className="actions">
              <button id="account-close" className="warn" onClick={cancel}>
                Cancel
              </button>
              <button className="action" id="account" onClick={saveCustomer}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
