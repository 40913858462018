import { createSlice } from "@reduxjs/toolkit";

export const userCurrentServiceSlice = createSlice({
  name: "userCurrentService",
  initialState: {
    value: [],
  },
  reducers: {
    setusercurrentservice: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setusercurrentservice } = userCurrentServiceSlice.actions;
export default userCurrentServiceSlice.reducer;
