import React, { useRef, useState } from "react";

export const NewInvoiceContactInfoSection = (props) => {
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const firstnameRef = useRef(null);
  const lastnameRef = useRef(null);
  const [firstnameError, setFirstnameError] = useState(null);
  const [lastnameError, setLastnameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);

  const handleSave = (event) => {
    event.preventDefault();
    let details = {
      customer_firstname: firstnameRef.current.value,
      customer_lastname: lastnameRef.current.value,
      customer_email: emailRef.current.value,
      customer_phone: phoneRef.current.value,
    };
    let phoneStatus = details.customer_phone === "" ? false : true;
    let emailStatus = details.customer_email === "" ? false : true;
    let firstnameStatus = details.customer_firstname === "" ? false : true;
    let lastnameStatus = details.customer_lastname === "" ? false : true;

    !firstnameStatus
      ? setFirstnameError("First Name*")
      : setFirstnameError(false);
    !lastnameStatus ? setLastnameError("Last Name*") : setLastnameError(false);
    !emailStatus ? setEmailError("Email*") : setEmailError(false);
    !phoneStatus ? setPhoneError("Phone*") : setPhoneError(false);

    if (phoneStatus && emailStatus && firstnameStatus && lastnameStatus) {
      props.save(details);
    } else {
      console.log("errors");
    }
  };
  return (
    <div className="details-container">
      <div className="title-head">
        <h4>Customer Contact Info</h4>
        <button className="action-style-two">Import from Customers</button>
      </div>
      <div className="form-container">
        {firstnameError ? (
          <span className="error">{firstnameError}</span>
        ) : (
          <span>First Name</span>
        )}
        <input type="text" placeholder={"Jaden"} ref={firstnameRef} />
        {lastnameError ? (
          <span className="error">{lastnameError}</span>
        ) : (
          <span>Last Name</span>
        )}
        <input type="text" placeholder={"Smith"} ref={lastnameRef} />
        {emailError ? (
          <span className="error">{emailError}</span>
        ) : (
          <span>Email</span>
        )}
        <input type="text" placeholder={"abc@goodemail.com"} ref={emailRef} />
        {phoneError ? (
          <span className="error">{phoneError}</span>
        ) : (
          <span>Phone</span>
        )}
        <input type="text" placeholder={"123-456-7890"} ref={phoneRef} />
      </div>
      <div className="title-foot">
        <div className="actions">
          <button className="blue" id="account" onClick={handleSave}>
            Save Contact Details
          </button>
        </div>
      </div>
    </div>
  );
};
