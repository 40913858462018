/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { createContext, useContext, useState } from "react";
import {
  getAuth,
  signInWithRedirect,
  GoogleAuthProvider,
  signOut,
  createUserWithEmailAndPassword,
  inMemoryPersistence,
  signInWithPopup,
  setPersistence,
} from "firebase/auth";
import {
  query,
  doc,
  setDoc,
  addDoc,
  getDoc,
  where,
  getDocs,
  collection,
  deleteDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../utils/firebase";
import { useNavigate } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
import {
  getStorage,
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
} from "firebase/storage";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  getusercustomers,
  removeusercustomers,
  setusercustomers,
} from "../../features/user/userCustomersSlice";
import {
  getuserservices,
  removeuserservices,
  setuserservice,
  setuserservices,
} from "../../features/user/userServicesSlice";
import { setuserinvoices } from "../../features/user/userInvoicesSlice";
import { logoutuser, login, update } from "../../features/user/userSlice";
import { setcustomer } from "../../features/customers/customerSlice";
import { setcustomerservices } from "../../features/customers/customerServicesSlice";
import { setcustomerservice } from "../../features/customers/customerServiceSlice";
import ServiceDetails from "./../../pages/Services/ServiceDetails";
import { userCurrentServiceSlice } from "./../../features/user/userCurrentServiceSlice";
import { setusercurrentservice } from "../../features/user/userCurrentServiceSlice";

// after initializing Firebase
const functions = getFunctions();
const analytics = getAnalytics();

logEvent(analytics, "notification_received");

const AuthContext = createContext();
export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  // REDUX //// REDUX //// REDUX //// REDUX //// REDUX //// REDUX //// REDUX //// REDUX //// REDUX //// REDUX //// REDUX //// REDUX //
  const dispatch = useDispatch();

  // Redux user globals
  const user = useSelector((state) => state.user.value);

  // ALL USER CUSTOMERS
  const userCustomers = useSelector((state) => state.userCustomers.value);

  // ALL USER INVOICES
  const userInvoices = useSelector((state) => state.userInvoices.value);

  // ALL USER SERVICES
  const userServices = useSelector((state) => state.userServices.value);

  // Redux customer globals
  const customer = useSelector((state) => state.customer.value);

  // ALL CUSTOMER SERVICES
  const customerServices = useSelector((state) => state.customerServices.value);

  // CUSTOMER SPECIFIC SERVICE
  const customerService = useSelector((state) => state.customerService.value);

  // ALL CUSTOMER INVOICES
  const customerInvoices = useSelector((state) => state.customerInvoices.value);

  // CUSTOMER SPECIFIC INVOICE
  const customerInvoice = useSelector((state) => state.customerInvoice.value);

  const [loading, setLoading] = useState(false);
  // const [user, setUser] = useState(null)
  const [slicedCustomers, setSlicedCustomers] = useState([]);
  const [slicedServices, setSlicedServices] = useState([]);
  const [customerData, setCustomerData] = useState(null);
  const [currentInvoice, setCurrentInvoice] = useState([null]);
  const [currentInventory, setCurrentInventory] = useState([]);
  const [needsToUpdate, setNeedsToUpdate] = useState(false);
  const [currentImageURL, setCurrentImageURL] = useState([]);
  const auth = getAuth();
  const storage = getStorage();
  const storageRef = ref(storage);
  const navigate = useNavigate();
  const [inventoryUpload, setInventoryUploadData] = useState(null);
  const devBaseAPI_URL = `http://localhost:5000/service-buddy-c0818/us-central1/api/paymentTest`;
  const webhook = `https://us-east4-service-buddy-c0818.cloudfunctions.net/ext-firestore-stripe-payments-handleWebhookEvents`;
  const testPayment = async () => {
    // const config = {
    //   method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //   credentials: 'same-origin', // include, *same-origin, omit
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    // }
    // const result = await fetch('http://localhost:5000/service-buddy-c0818/us-central1/api/paymentTest', config)
    // console.log(result)

    const stripeTestWebHookSecret = "whsec_hw4HUgproGf0NJvIrGCygwrV10iMnQP5";
    const stripeResrstrictedKey =
      "rk_test_51MFjaBJzJ7XYfHkbBuEVJd0A01rWoyPpBW6uVQcnntR9szofcN4RSKglVDgx4bg8vkjPwkICiCooBzincGxnaA3q00xN1qBc1q";
    const stripeTestProID = `price_1MFlMhJzJ7XYfHkbbPLhLkMy`;

    const stripeProdWebHookSecret = ``;
    const stripeProdRestrictedKey = ``;
    const stripeProd_Pro_Plan = `price_1MFpM7JzJ7XYfHkbJKYhAc0T`;

    const purchaseDetails = {
      price: stripeTestProID,
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    };
    const docRef = await addDoc(
      collection(db, "subscribers", user.uid, "checkout_sessions"),
      purchaseDetails
    ).then(async (data) => {
      let refId = data.id;
      const newDocRef = doc(
        db,
        "subscribers",
        user.uid,
        "checkout_sessions",
        refId
      );
      onSnapshot(
        doc(db, "subscribers", user.uid, "checkout_sessions", refId),
        (doc) => {
          const { error, url } = doc.data();
          if (error) {
            console.log(error);
          }
          if (url) {
            window.location.assign(url);
          }
        }
      );
      // const querySnapshot = doc(db, "users", user.uid, "customers", doc.id);
      // console.log(await getDoc(querySnapshot))
    });
  };
  const setInventoryDataFromFile = (data) => {
    setCurrentInvoice(data);
  };
  const goToPortal = () => {
    // const functionRef = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    // const { data } = await functionRef({ returnUrl: window.location.origin,  });
    window.location.assign(
      `https://billing.stripe.com/p/login/test_aEU3dU8YN8cv3ba288`
    );
  };
  const fetchCustomerServiceCount = async (receivedUser, customerId) => {
    const serviceCountRef = await getDocs(
      collection(
        db,
        "users",
        receivedUser.uid,
        "customers",
        customerId,
        "service"
      )
    );
    let size = serviceCountRef.size;
    return size;
  };

  // if user object needs updated run this will update localstorage, redux, and database
  const updateUserData = async (newUserObject) => {
    dispatch(login(newUserObject));
    localStorage.setItem("user", JSON.stringify(newUserObject));
    await setDoc(doc(db, "users", newUserObject.uid), newUserObject);
    return true;
  };
  // Get data from redux ////////////////////////////////////

  //   dispatch(setcustomer(myCustomer[0]));
  //   return myCustomer[0];
  // };
  const fetchServiceDetails = async (serviceId) => {
    let retrievedData;
    if (!user) {
      return;
    } else {
      const docRef = doc(db, "users", user.uid, "services", serviceId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let retrievedData = docSnap.data();
        dispatch(setusercurrentservice(retrievedData));
      }
    }
    return retrievedData;
  };
  const fetchAllUserCustomers = async (receivedUserObject) => {
    let uid = receivedUserObject.uid;
    const querySnapshot = await getDocs(
      collection(db, "users", uid, "customers")
    );
    if (querySnapshot.empty) {
      dispatch(setusercustomers([]));
    } else {
      let customers = [];
      for (const doc of querySnapshot.docs) {
        let customer = doc.data();
        customer.id = doc.id;
        customer.service_count = await fetchCustomerServiceCount(
          receivedUserObject,
          doc.id
        );
        customers = [...customers, customer];
      }
      dispatch(setusercustomers(customers));
    }
    return true;
  };
  const fetchAllUserServices = async (receivedUserObject) => {
    let uid = receivedUserObject.uid;
    const querySnapshot = await getDocs(
      collection(db, "users", uid, "services")
    );
    if (querySnapshot.empty) {
      dispatch(setuserservices([]));
    } else {
      let services = [];
      for (const doc of querySnapshot.docs) {
        let service = doc.data();
        service.service_id = doc.id;
        services = [...services, service];
      }
      dispatch(setuserservices(services));
      if (services.length >= 3) {
        let sliced = services.slice(0, 2);
        setSlicedServices(sliced);
      } else {
        setSlicedServices(services);
      }
    }
    return true;
  };
  const fetchAllClientServices = async (clientId) => {
    let retrievedData = [];
    const q = query(
      collection(db, "users", user.uid, "services"),
      where("customerId", "==", clientId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // console.log(doc.id, " => ", doc.data());
      let thisData = doc.data();
      thisData.id = doc.id;
      retrievedData.push(thisData);
    });
    let totalHours = 0;
    retrievedData.forEach((service) => {
      totalHours = totalHours + parseFloat(service.hours);
    });
    retrievedData.totalHours = totalHours;
    return retrievedData;
  };
  const deleteCustomer = async (customerId) => {
    await deleteDoc(doc(db, "users", user.uid, "customers", customerId));
    let clientsServices = await fetchAllClientServices(customerId);
    clientsServices.forEach(async (service) => {
      await deleteDoc(doc(db, "users", user.uid, "services", service.id));
    });
    await fetchAllUserCustomers(user);
  };
  const deleteServiceLog = async (service) => {
    console.log(service);
    console.log(user);
    await deleteDoc(doc(db, "users", user.uid, "services", service.service_id));
    await deleteDoc(
      doc(
        db,
        "users",
        user.uid,
        "customers",
        service.id,
        "service",
        service.service_id
      )
    );
    fetchAllUserServices(user);
  };

  const addNewCustomer = async (customerDetails) => {
    const docRef = doc(collection(db, "users", user.uid, "customers"));
    await setDoc(docRef, customerDetails);
    await fetchAllUserCustomers(user);
    return docRef.id;
  };

  const updateCustomer = async (customerDetails) => {
    const docRef = doc(db, "users", user.uid, "customers", customerDetails.id);
    await updateDoc(docRef, customerDetails);
    await fetchAllUserCustomers(user);
    await fetchCustomerData(customerDetails.id);
    return docRef.id;
  };
  const fetchCustomerData = async (customerID) => {
    console.log("updating current customer");
    // let hours = 0;

    // let myCustomer = userCustomers.filter((e) => {
    //   return e.id === customerId;
    // });
    // if (userServices.length > 0) {
    //   let customerservices = userServices.filter((e) => {
    //     return e.cid === customerId;
    //   });
    // let totalhours = {
    //   hours: hours
    // }
    // customerservices = [...customerservices, totalhours]
    // dispatch(setcustomerservices(customerservices));
    let retrievedData;

    const docRef = doc(db, "users", user.uid, "customers", customerID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let retrievedData = docSnap.data();
      console.log(retrievedData);
      dispatch(setcustomer(retrievedData));
    } else {
      console.log("No such customer");
    }
    return retrievedData;
  };

  const updateUserInfo = (newData) => {
    const docRef = doc(db, "users", user.uid);
    setDoc(docRef, newData, { merge: true });
  };
  const logout = () => {
    signOut(auth)
      .then(() => {
        dispatch(logoutuser());
        dispatch(removeusercustomers());
        dispatch(removeuserservices());
        setSlicedCustomers(null);
        setSlicedServices(null);
        setCustomerData(null);
        dispatch(setuserservices(null));
        setCurrentInventory(null);
        setCurrentInvoice(null);
        localStorage.clear();
        navigate("/");
      })
      .catch((error) => {
        // An error happened.
      });
  };
  const googleSignIn = async (event) => {
    event.preventDefault();
    setPersistence(auth, inMemoryPersistence)
      .then(async () => {
        const provider = new GoogleAuthProvider();

        let result = await signInWithPopup(auth, provider);
        setLoading(true);
        initialLoginCheck(result.user);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };
  const emailAndPasswordLogin = (email, password) => {
    signInWithRedirect(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        initialLoginCheck(user);
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };
  const registerNewUser = (email, password) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        initialLoginCheck(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };
  const checkIfUserInDatabase = async (receivedUserObject) => {
    let uid = receivedUserObject.uid;
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let currentUser = docSnap.data();
      if (
        !currentUser.firstname ||
        !currentUser.lastname ||
        !currentUser.business_details.business_name ||
        !currentUser.business_details.business_streets ||
        !currentUser.business_details.business_suite ||
        !currentUser.business_details.business_city ||
        !currentUser.business_details.business_state ||
        !currentUser.business_details.business_phone ||
        !currentUser.business_details.business_email
      ) {
        let updatedUser = {
          uid: receivedUserObject.uid,
          email: currentUser.email || "",
          phone: currentUser.phoneNumber || "",
          firstname: currentUser.firstname || "",
          lastname: currentUser.lastname || "",
          createdAt: currentUser.createdAt,
          accessToken: receivedUserObject.accessToken,
          business_details: {
            business_name: currentUser.business_details.business_name,
            business_street: currentUser.business_details.business_street,
            business_suite: currentUser.business_details.business_name,
            business_state: currentUser.business_details.business_state,
            business_city: currentUser.business_details.business_city,
            business_phone: currentUser.business_details.business_phone,
            business_email: currentUser.business_details.business_email,
          },
          preferences: [],
          service_started: currentUser.service_started,
          unfinished_services: currentUser.unfinished_services,
          unreviewed_services: currentUser.unreviewed_services,
        };
        setNeedsToUpdate(true);
        updateUserData(updatedUser);
      }
    } else {
      let currentUser = {
        uid: receivedUserObject.uid,
        email: receivedUserObject.email || "",
        phone: receivedUserObject.phoneNumber || "",
        firstname: receivedUserObject.firstname || "",
        lastname: receivedUserObject.lastname || "",
        createdAt: Date.now(),
        accessToken: receivedUserObject.accessToken,
        business_details: {
          business_name: "",
          business_street: "",
          business_suite: "",
          business_state: "",
          business_city: "",
          business_phone: "",
          business_email: "",
        },
        preferences: [],
        service_started: false,
        unfinished_services: [],
        unreviewed_services: [],
      };
      updateUserData(currentUser);
    }
    return true;
  };

  const addNewService = async (serviceData) => {
    // Remove from user object
    const serviceRef = await addDoc(
      collection(db, "users", user.uid, "services"),
      serviceData
    );
    const customerServiceRef = doc(
      db,
      "users",
      user.uid,
      "customers",
      serviceData.id,
      "service",
      serviceRef.id
    );
    setDoc(customerServiceRef, serviceData);

    let updatedUserObject = { ...user };
    let returnedIndex = updatedUserObject.unreviewed_services.find(
      (object) => object.id === serviceData.id
    );

    updatedUserObject["unreviewed_services"] = user[
      "unreviewed_services"
    ].filter((item) => item.id !== serviceData.id);
    updateUserData(updatedUserObject);
    fetchAllUserServices(user);
  };
  const fetchAllUserInvoices = async (receivedUserObject) => {
    let uid = receivedUserObject.uid;
    const querySnapshot = await getDocs(
      collection(db, "users", uid, "invoices")
    );
    if (querySnapshot.empty) {
      dispatch(setuserinvoices([]));
    } else {
      let invoices = [];
      for (const doc of querySnapshot.docs) {
        let invoice = doc.data();
        invoice.id = doc.id;
        invoices = [...invoices, invoice];
      }
      dispatch(setuserinvoices(invoices));
    }
    return true;
  };
  const addNewInvoice = async (invoiceData) => {
    if (invoiceData.type === "custom") {
      const invoiceRef = await addDoc(
        collection(db, "users", user.uid, "invoices"),
        invoiceData
      );
      invoiceData.id = invoiceRef.id;
      const userInvoiceRef = doc(
        db,
        "users",
        user.uid,
        "invoices",
        invoiceRef.id
      );
      setDoc(userInvoiceRef, invoiceData);
      fetchAllUserInvoices(user);
    }
    // Add to user invoices and add to customer invoices
  };
  function removeObjectFromArray(array, id) {
    return array.filter((obj) => obj.id !== id);
  }
  const removeServiceFromUsersServicesAndUpdate = async (service) => {
    const readOnlyArray = userServices;
    let newServicesArray = removeObjectFromArray(
      readOnlyArray,
      service.service_id
    );
    dispatch(setuserservices(newServicesArray));
    deleteServiceLog(service);
    if (newServicesArray.length + 1 === userServices.length) {
      return true;
    } else {
      return false;
    }
  };

  const finalizeAndRemoveObjectFromUnfinishedServiceArray = async (service) => {
    let updatedUserObject = { ...user };
    let returnedIndex = updatedUserObject.unfinished_services.find(
      (object) => object.id === service.id
    );
    returnedIndex = { ...returnedIndex };
    returnedIndex.stoppedAt = Date.now();
    updatedUserObject["unfinished_services"] = user[
      "unfinished_services"
    ].filter((item) => item.id !== service.id);
    let finalUpdatedObject = { ...updatedUserObject };
    let newArray = [...finalUpdatedObject.unreviewed_services];
    newArray.push(service);
    finalUpdatedObject.unreviewed_services = newArray;
    if (finalUpdatedObject.unfinished_services.length > 0) {
      console.log("Only update the array");
      updateUserData(finalUpdatedObject);
    } else {
      finalUpdatedObject.service_started = false;
      updateUserData(finalUpdatedObject);
    }
    return returnedIndex;
  };

  const initialLoginCheck = async (receivedUserObject) => {
    let userChecked = await checkIfUserInDatabase(receivedUserObject);
    let customersFetched = await fetchAllUserCustomers(receivedUserObject);
    let servicesFetched = await fetchAllUserServices(receivedUserObject);
    let invoicesFetched = await fetchAllUserInvoices(receivedUserObject);
    if (userChecked && customersFetched && servicesFetched && invoicesFetched) {
      setLoading(false);
      navigate("/dashboard");
    } else {
      setLoading(false);
      navigate("/");
    }
  };
  function getHours(endTime, date) {
    var current;
    if (!endTime) {
      current = new Date();
    } else {
      current = new Date(endTime);
    }
    var previous = new Date(date);
    var diff = current - previous;
    var hours = Math.floor(diff / 1000 / 60 / 60);
    var minutes = Math.floor(diff / 1000 / 60) % 60;
    let convertedMinutes = minutes / 60;
    let totalTime = hours + convertedMinutes;
    // let timeDifference = {
    //   hours: hours,
    //   minutes: minutes,
    //   minutesInDec: convertedMinutes.toFixed(2),
    //   totalTime: totalTime.toFixed(2),
    // };
    return totalTime.toFixed(2);
  }

  const updateCurrentService = async (serviceDetails, imageData) => {
    const customerServiceRef = doc(
      db,
      "users",
      user.uid,
      "services",
      serviceDetails.service_id
    );
    let newServiceDetails = { ...serviceDetails };
    console.log(newServiceDetails);
    if (newServiceDetails.images.some((e) => e.name === imageData.name)) {
      console.log("duplicate");
    } else if (newServiceDetails.images.length === 0) {
      newServiceDetails.images = [imageData];
      updateDoc(customerServiceRef, newServiceDetails);
      await fetchServiceDetails(serviceDetails.service_id);
    } else {
      newServiceDetails.images = [...newServiceDetails.images, imageData];
      updateDoc(customerServiceRef, newServiceDetails);
      await fetchServiceDetails(serviceDetails.service_id);
    }
    return newServiceDetails;
  };

  const handleServiceImageUpload = async (service, image) => {
    const serviceImageRef = ref(
      storage,
      `users/${user.uid}/service/${service.service_id}/${image.name}`
    );
    uploadBytes(serviceImageRef, image).then(() => {
      getDownloadURL(
        ref(
          storage,
          `users/${user.uid}/service/${service.service_id}/${image.name}`
        )
      )
        .then(async (url) => {
          let imageData = {
            url: url,
            name: image.name,
          };
          await updateCurrentService(service, imageData);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  /////////////////////////////////////SERVICES/////////////////////////////////////
  const beginService = async (serviceDetails) => {
    console.log("beginning service");
    //Add Service Log to user database
    let newServiceDetails = {
      ...serviceDetails,
      startedBy: user.uid,
      startedAt: Date.now(),
      stoppedAt: null,
      reviewed: false,
      invoice_created: false,
      images: [],
    };
    const newServiceRef = await addDoc(
      collection(db, "users", user.uid, "services"),
      newServiceDetails
    );
    newServiceDetails.service_id = newServiceRef.id;
    const customerServiceRef = doc(
      db,
      "users",
      user.uid,
      "services",
      newServiceRef.id
    );
    setDoc(customerServiceRef, newServiceDetails);
    await fetchAllUserServices(user);
    return newServiceDetails;
  };
  const finalizeService = async (serviceDetails) => {
    let updatedServiceDetails = { ...serviceDetails };
    updatedServiceDetails.stoppedAt = Date.now();
    updatedServiceDetails.hours = getHours(
      updatedServiceDetails.stoppedAt,
      updatedServiceDetails.startedAt
    );

    let serviceId = serviceDetails.service_id;
    const customerServiceRef = doc(
      db,
      "users",
      user.uid,
      "services",
      serviceId
    );
    await setDoc(customerServiceRef, updatedServiceDetails);
    await fetchAllUserServices(user);
  };
  // Write Service to database with status as unfinished
  // Get ID from database for the service
  // Create unfinished services for User

  // DELETE WHEN DONE

  useEffect(() => {
    let cachedUser = localStorage.getItem("user");
    cachedUser = JSON.parse(cachedUser);
    if (cachedUser) {
      initialLoginCheck(cachedUser);
    }
  }, []);

  const value = {
    loading,
    getHours,
    handleServiceImageUpload,
    beginService,
    finalizeService,
    setLoading,
    googleSignIn,
    user,
    logout,
    registerNewUser,
    emailAndPasswordLogin,
    fetchAllUserCustomers,
    fetchAllUserServices,
    fetchAllClientServices,
    fetchServiceDetails,
    userServices,
    addNewCustomer,
    updateCustomer,
    slicedCustomers,
    fetchCustomerData,
    customerData,
    addNewService,
    deleteCustomer,
    deleteServiceLog,
    testPayment,
    goToPortal,
    setCurrentInvoice,
    currentInvoice,
    setInventoryDataFromFile,
    currentInventory,
    setCurrentInventory,
    needsToUpdate,
    setNeedsToUpdate,
    updateUserInfo,
    updateUserData,
    finalizeAndRemoveObjectFromUnfinishedServiceArray,
    removeServiceFromUsersServicesAndUpdate,
    addNewInvoice,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
